import React from "react";

const CompanyDetails = () => {
  return (
    <div className="pt-48 px-64">
      <div className="grid grid-cols-2 gap-10 text-white relative">
        <div className="absolute h-full w-full -top-96 left-0 -z-10">
          <img src="/images/bg-shadow2.webp" alt="" className="" />
        </div>
        {/* left */}
        <div className="flex flex-col gap-20">
          {/* top */}
          <div className="bg-[#231b42] py-10 px-5">
            <div className="w-12 h-10 rounded-full bg-white mb-10"></div>
            <h1 className="text-5xl mb-5">Lorem ipsum dolor sit amet.</h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Recusandae, aliquam?
            </p>
          </div>
          {/* bottom */}
          <div className="">
            <h1 className="text-5xl mb-5">Lorem ipsum dolor sit amet.</h1>
            <p className="font-light text-lg">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
              aspernatur molestias voluptatum rem repellendus velit repellat
              doloribus accusantium unde, impedit id, aliquam sunt! Labore
              quibusdam, esse ullam amet aut mollitia? A expedita aut culpa,
              aliquam dicta ducimus architecto doloribus ipsam.
            </p>
          </div>
        </div>
        {/* right */}
        <div className="flex flex-col gap-10">
          {/* top-right */}
          <div className="">
            <span className="text-[#8856c9] font-bold text-lg">
              Lorem, ipsum.
            </span>
            <h1 className="text-5xl mb-5">Lorem ipsum dolor sit amet.</h1>
          </div>

          {/* bottom-right */}
          <div className="">
            <img
              src="/images/red.png"
              alt=""
              className=" w-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
