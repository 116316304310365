import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import data from "../../constants/data.json";
import BlogItem from "./BlogItem";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const blogs = data?.blogItems;
  const { t } = useTranslation("global");
  return (
    <div className="overflow-hidden relative px-10 h-full w-full bg-[#F0F1FA] max-sm:-mt-30">
      {/* Başlık */}
      <div className="flex justify-between items-center mb-10 w-full hf">
        <div className="relative h-full w-full mt-4">
          <h1 className="max-md:text-2xl text-5xl  md:ps-6">
            {t("homepage.blog.title")}
          </h1>
        </div>
        {/* Swiper Slider */}
        <div className="absolute max-sm:right-16 max-sm:top-8 right-40 top-6 flex justify-center items-center">
          
          <button className="max-sm:w-10 max-sm:h-10 max-md:w-14 max-md:h-14 w-16 h-16 rounded-full bg-[#202126] border border-[#595b64] transition-all duration-300 hover:border-white swiper-button-next group">
            <i class="ri-arrow-left-line text-xl text-[#595b64] group-hover:text-white transition-all duration-300"></i>
          </button>
          <button className="max-sm:w-10 max-sm:h-10 max-md:w-14 max-md:h-14 w-16 h-16 rounded-full bg-[#202126] border border-[#595b64] transition-all duration-300 hover:border-white swiper-button-prev group">
            <i class="ri-arrow-right-line text-xl text-[#595b64] group-hover:text-white transition-all duration-300"></i>
          </button>
        </div>
      </div>

      {/* blog-items */}
      <div className="h-full w-full relative z-30 pb-20">
        <Swiper
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            640: { slidesPerView: 1 },
            850: { slidesPerView: 2 },
            1280: { slidesPerView: 3 },
          }}
        >
          {blogs.map((blog, index) => (
            <SwiperSlide key={index}>
              <BlogItem blog={blog} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Blog;
