import "./App.css";
import Home from "./pages/Home";
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogDetails from "./pages/BlogDetails";
import ContactPage from "./pages/ContactPage";
import BlogsPage from "./pages/BlogsPage";
import WorkPage from "./pages/WorkPage";
import CompanyDetails from "./pages/CompanyDetails";
import CookiePage from "./pages/static/CookiePage";
import KvkkPage from "./pages/static/KvkkPage";
import PrivacyPage from "./pages/static/PrivacyPage";
import ReferancePage from "./pages/ReferancePage";
import OfferPage from "./pages/OfferPage";
import { ToastContainer } from "react-toastify";
import SmoothScroll from "./components/layouts/SmoothScroll";
import SplashCursor from "./components/layouts/SplashCursor";

function App() {
  return (
    <Router>
      <div className="max-md:hidden">
      <SplashCursor />
      </div>
      <ToastContainer position="top-right" />
      <div className="">
        <Header />
        <Routes>
          <Route element={<SmoothScroll><Home /></SmoothScroll>} path="/" />
          <Route element={<SmoothScroll><BlogsPage /></SmoothScroll>} path="/blog" />
          <Route element={<SmoothScroll><BlogDetails /></SmoothScroll>} path="/blog/:slug" />
          <Route element={<SmoothScroll><ReferancePage /></SmoothScroll>} path="/referanslar" />
          <Route element={<ContactPage />} path="/iletisim" />
          <Route element={<WorkPage />} path="/hizmetler/:slug" />
          <Route element={<CompanyDetails />} path="/sirket/:slug" />
          <Route element={<SmoothScroll><CookiePage /></SmoothScroll>} path="/cerez-politikasi" />
          <Route element={<SmoothScroll><KvkkPage /></SmoothScroll>} path="/kisisel-verilerin-korunmasi" />
          <Route element={<SmoothScroll><PrivacyPage /></SmoothScroll>} path="/gizlilik-politikasi" />
          <Route element={<OfferPage />} path="/teklif-al" />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
