import React from "react";

const BigCardItem = ({ item }) => {
  return (
    <div
      className={`ref-card cursor-pointer group transition-all duration-500`}
    >
      <div className="card-img relative overflow-hidden rounded-2xl transition-all duration-500">
        <div className="relative">
          <img
            src={item?.image}
            alt=""
            className="rounded-2xl object-cover h-full w-full max-sm:max-h-[300px] max-sm:min-h-[300px] sm:max-h-[400px] sm:min-h-[400px] xl:max-h-[450px] xl:min-h-[450px] overflow-hidden group-hover:border-gray-300 border-2 border-black transition-all duration-500 opacity-100 group-hover:opacity-0"
          />
          <div className="absolute top-0 left-0">
            <img
              src={item?.image2}
              alt=""
              className="rounded-2xl object-cover h-full max-sm:max-h-[300px] w-screen max-sm:min-h-[300px] sm:max-h-[400px] sm:min-h-[400px] xl:max-h-[450px] xl:min-h-[450px] overflow-hidden group-hover:border-gray-300 border-2 border-black transition-all duration-500 opacity-0 group-hover:opacity-100"
            />
          </div>
        </div>

        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-10" />
      </div>
      <div className="card-text ps-2 mt-5">
        <h1 className="text-4xl inter-medium">{item?.title}</h1>
      </div>
    </div>
  );
};

export default BigCardItem;
