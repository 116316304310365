import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ScrollBar = () => {
  const [innerWidth, setInnerWidth] = useState(768);

  useEffect(() => {
    setInnerWidth(window.innerWidth);

    const scroll = () => {
      const path = document.querySelector(".cls-1");
      if (!path) return;

      const distance = window.scrollY;
      const totalDistance =
        innerWidth < 768
          ? window.innerHeight
          : document.body.clientHeight - window.innerHeight;

      const offset =
        window.innerWidth <= 768 ? 100 : window.innerWidth <= 1024 ? 300 : window.innerWidth <= 1680 ? 2300 : 2800;
      let percentage = (distance - offset) / totalDistance;

      // Scroll animasyonunu hızlandırmak için yüzdeyi artırıyoruz
      percentage = Math.min(
        percentage *
          (window.innerWidth <= 768 ? 7 : window.innerWidth <= 1024 ? 6 : window.innerWidth <= 1680 ? 2.8 : 3),
        1
      );

      const pathLength = path.getTotalLength();

      path.style.strokeDasharray = pathLength;
      path.style.strokeDashoffset = pathLength * (1 - percentage);
    };

    window.addEventListener("scroll", scroll);
    return () => window.removeEventListener("scroll", scroll);
  }, []);

  return (
    <div
      className="absolute left-0 top-0 w-full z-10"
      style={{ height: "100%" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 236.97 452.92"
        style={{ width: "100%", height: "auto" }}
      >
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "red", stopOpacity: 1 }} />
            <stop
              offset="100%"
              style={{ stopColor: "purple", stopOpacity: 1 }}
            />
          </linearGradient>

        </defs>
        <path
          className="cls-1"
          d="M1.2,3.29s72.64,26.62,54.48,72.89c-18.16,46.27-18.81,74.79,25.3,64.65,44.1-10.14,156.31-101.42,140.1-17.11C204.86,208.02,10.93,296.12,23.91,234.01c12.97-62.12,83.67-106.49,127.12-46.9,43.46,59.58-46.05,167.97-38.27,175.58,7.78,7.61,123.88-70.99,120.64-1.9-3.24,69.09-85.61,88.74-85.61,88.74"
        />
      </svg>
    </div>
  );
};

export default ScrollBar;
