import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/layouts/Breadcrumb";
import { useTranslation } from "react-i18next";
import BigCardItem from "../components/layouts/BigCardItem";
const ReferancePage = () => {
  const [breadcrumbPath, setBreadcrumbPath] = useState([
    { name: "Anasayfa", url: "/" },
    { name: "Referanslar" },
  ]);

  const { t } = useTranslation("global");
  const data = t("homepage.reference.referenceItems", { returnObjects: true });

  useEffect(() => {}, []);
  return (
    <div className="relative w-full min-h-screen">
      <div className="absolute top-0 left-0 w-full h-full -z-10">
        <img
          src="/images/bg-shadow4.webp"
          alt="Background image"
          className="w-full h-full "
        />
      </div>

      <div className="relative flex justify-center items-center flex-col gap-16 w-full max-sm:min-h-[600px] sm:h-screen pt-32">
        <div className="flex justify-center items-center flex-col gap-4">
          <div className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl text-center">
            Farkınızı yansıtan benzersiz <br /> bir web sitesi sizinle!
          </div>
          <Breadcrumb paths={breadcrumbPath} />
        </div>

        <div
          className="max-sm:hidden h-16 w-16 rounded-full flex justify-center items-center cursor-pointer border border-black group hover:border-white transition-all duration-300"
          onClick={() => document.getElementById("cards").scrollIntoView({ behavior: "smooth" })}
        >
          <i className="ri-arrow-down-line text-xl group-hover:text-white text-[black] transition-all duration-300" />
        </div>

        {/* <div className="sm:w-1/2 w-full h-16 sm:h-28 rounded-full bg-[#0D0E18] bg-opacity-90 sm:p-5 sm:pt-10 px-10">
          <input
            type="text"
            name=""
            placeholder="Proje ismi giriniz"
            className="w-full h-full rounded-full bg-transparent sm:p-5 border"
          />
        </div> */}

        <div className="absolute bottom-5 left-0 w-full flex justify-center items-center mt-10">
          <div className="relative w-5/6 max-sm:h-[3px] max-sm:bg-white h-[1px] bg-[#ffffff80] rounded-full ">
            <div className="absolute max-sm:w-32 sm:w-64 h-full bg-gradient-to-r from-[#0D0E18] to-transparent"></div>
            <div className="absolute top-0 right-0 max-sm:w-32 sm:w-64 h-full bg-gradient-to-l from-[#0D0E18] to-transparent"></div>
          </div>
        </div>
      </div>

      <section id="cards" className="px-10 lg:px-16 xl:px-32 mt-10 mb-10">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 max-md:grid-cols-1 gap-10 ">
          {data?.map((item, index) => (
            <BigCardItem item={item} key={index} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ReferancePage;
