import React, { useEffect, useState } from "react";
import { MdOutlineTextDecrease, MdOutlineTextIncrease } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/layouts/Breadcrumb";
import Footer from "../../components/layouts/Footer";

const PrivacyPage = () => {
  const [breadcrumbPath, setBreadcrumbPath] = useState([
    { name: "Anasayfa", url: "/" },
    { name: "Gizlilik Politikası" },
  ]);
  const [fontSize, setFontSize] = useState({
    title: "max-md:text-3xl text-5xl",
    desc: "text-xl",
    number: 3,
  });

  const { t } = useTranslation("global");

  const getIconSize = () => {
    if (window.innerWidth < 620) return 24;
    return 32;
  };
  const [iconSize, setIconSize] = useState(getIconSize());

  // functions
  const increaseFont = () => {
    setFontSize((prevFontSize) => {
      const newNumber = Math.min(5, prevFontSize.number + 1); // 5'in üzerine çıkmasını önler

      return {
        ...prevFontSize,
        number: newNumber,
        title:
          newNumber === 1
            ? "max-md:text-xl text-3xl"
            : newNumber === 2
            ? "max-md:text-2xl text-4xl"
            : newNumber === 3
            ? "max-md:text-3xl text-5xl"
            : newNumber === 4
            ? "max-md:text-4xl text-6xl"
            : "max-md:text-5xl text-7xl",
        desc:
          newNumber === 1
            ? "text-sm"
            : newNumber === 2
            ? "text-lg"
            : newNumber === 3
            ? "text-xl"
            : newNumber === 4
            ? "text-2xl"
            : "text-3xl",
      };
    });
  };
  const decreaseFont = () => {
    setFontSize((prevFontSize) => {
      const newNumber = Math.max(1, prevFontSize.number - 1); // 1'in altına düşmesini önler
      return {
        ...prevFontSize,
        number: newNumber,
        title:
          newNumber === 1
            ? "max-md:text-xl text-3xl"
            : newNumber === 2
            ? "max-md:text-2xl text-4xl"
            : newNumber === 3
            ? "max-md:text-3xl text-5xl"
            : newNumber === 4
            ? "max-md:text-4xl text-6xl"
            : "max-md:text-5xl text-7xl",
        desc:
          newNumber === 1
            ? "text-sm"
            : newNumber === 2
            ? "text-lg"
            : newNumber === 3
            ? "text-xl"
            : newNumber === 4
            ? "text-2xl"
            : "text-3xl",
      };
    });
  };
  const whatsappClickHandle = () => {
    const currentURL = window.location.href;
    const message = `Gizlilik Politikası: `;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      message + currentURL
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  const linkedinClickHandle = () => {
    const message = `Gizlilik Politikası: `;
    const url = encodeURIComponent(message + window.location.href);
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      "_blank"
    );
  };
  const facebookClickHandle = () => {
    const message = `Gizlilik Politikası: `;
    const url = encodeURIComponent(message + window.location.href);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "_blank"
    );
  };
  const twitterClickHandle = () => {
    const text = encodeURIComponent("Gizlilik Politikası");
    const url = encodeURIComponent(window.location.href);

    window.open(
      `https://twitter.com/intent/tweet?url=${url}&text=${text}`,
      "_blank"
    );
  };

  const stickyBar = [
    [
      {
        icon: <MdOutlineTextIncrease size={iconSize} />,
        func: increaseFont,
      },
      {
        icon: <MdOutlineTextDecrease size={iconSize} />,
        func: decreaseFont,
      },
      {
        icon: <RiFileCopyLine size={iconSize} />,
      },
    ],

    [
      {
        icon: <FaWhatsapp size={iconSize} />,
        func: whatsappClickHandle,
      },
      {
        icon: <FaFacebookF size={iconSize} />,
        func: facebookClickHandle,
      },
      {
        icon: <FaXTwitter size={iconSize} />,
        func: twitterClickHandle,
      },
      {
        icon: <FaLinkedinIn size={iconSize} />,
        func: linkedinClickHandle,
      },
    ],
  ];

  useEffect(() => {
    const handleResize = () => setIconSize(getIconSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="h-full w-full max-md:overflow-hidden relative select-none mb-32">
        <div className="max-sm:h-[650px] h-[450px] absolute w-full -z-20 overflow-hidden">
          <img
            src="/images/blog-bg.png"
            alt=""
            className="w-full h-full scale-110"
          />
        </div>

        <div className="text-white pt-48 flex justify-center items-center relative w-full h-full">
          <div className="flex max-md:flex-col gap-10 ">
            {/* sticky-bar */}
            <div className="md:sticky max-md:hidden w-10 h-full top-5 max-lg:left-4 z-20 ">
              <div className="flex md:flex-col justify-start items-center gap-20 w-full">
                <div className="flex flex-col gap-2">
                  {stickyBar[0]?.map((item, i) => (
                    <div
                      className={`cursor-pointer border-b border-[#e8e8e81a] pb-2`}
                      key={i}
                      onClick={item?.func}
                    >
                      {item?.icon}
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-2">
                  {stickyBar[1]?.map((item, i) => (
                    <div
                      className={`cursor-pointer border-b border-[#e8e8e81a] pb-2`}
                      key={i}
                      onClick={item?.func}
                    >
                      {item?.icon}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* background-shadow */}
            <div className="absolute top-32 left-0 w-full h-full -z-10">
              <img
                src="/images/bg-shadow2.webp"
                alt=""
                className="w-full h-screen"
              />
            </div>

            {/* content */}
            <div className="max-sm:w-full sm:max-w-[800px] ">
              <div className="max-sm:px-8">
                <Breadcrumb paths={breadcrumbPath} />
              </div>
              {/* title */}
              <div className="flex flex-col max-sm:px-8">
                <h1 className="max-md:text-4xl text-7xl">
                  {t("privacyPage.title")}
                </h1>
              </div>
              {/* sticky-bar-mobile */}
              <div className="w-full h-full top-0 left-0 mt-10 md:hidden z-30 flex justify-center items-center">
                <div className="flex md:flex-col justify-between w- items-center max-sm:gap-2 sm:gap-4 ">
                  <div className="flex gap-2">
                    {stickyBar[0]?.map((item, i) => (
                      <div
                        className={`cursor-pointer border-r border-[#e8e8e81a] pe-2 max-sm:text-sm`}
                        key={i}
                        onClick={item?.func}
                      >
                        {item?.icon}
                      </div>
                    ))}
                  </div>
                  <div className="flex gap-2 ">
                    {stickyBar[1]?.map((item, i) => (
                      <div
                        className={`cursor-pointer border-r border-[#e8e8e81a] pe-2`}
                        key={i}
                        onClick={item?.func}
                      >
                        {item?.icon}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {t("privacyPage.sections", { returnObjects: true })?.map(
                (section) => (
                  <div className="mt-10 max-sm:px-8">
                    {section?.title?.trim() !== "" && (
                      <h3 className={`${fontSize?.title}`}>{section?.title}</h3>
                    )}
                    {section?.content?.trim() !== "" && (
                      <p
                        className={`${fontSize?.desc} font-light mt-3`}
                        dangerouslySetInnerHTML={{ __html: section?.content }}
                      />
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPage;
