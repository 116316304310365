import React, { useEffect, useState } from "react";
import { gsap } from "gsap";

const Loading = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    // 3 saniye sonra hareket ettir
    setTimeout(() => {
      setIsMoving(true);
      gsap.to(".animated-image-lg", {
        x: -window.innerWidth / 2 + 210, // Sol üst köşeye gider
        y: -window.innerHeight / 2 + 25, 
        scale: 0.5, // Boyutu küçült
        duration: 1, // 1 saniyede yapsın
        ease: "power2.out",
      });

      gsap.to(".animated-image-sm", {
        x: -window.innerWidth / 2 + 100,
        y: -window.innerHeight / 2 + 25, 
        scale: 0.5,
        duration: 1,
        ease: "power2.out",
      });
    }, 2500);

    // 4 saniye sonra tamamen kaldır
    setTimeout(() => {
      setIsVisible(false);
    }, 3000);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-screen bg-[#090B10]  flex justify-center items-center transition-all duration-300 ${
        isVisible ? "opacity-100 z-50" : "opacity-0 -z-50"
      }`}
    >
      <div>
        <img
          src="/images/logo-beyaz.webp"
           alt="Ellusion Logo"
          className="max-sm:h-80 max-sm:w-80 h-96 w-96 animated-image-lg max-lg:hidden loading"
        />
        <img
          src="/images/logo-beyaz.webp"
           alt="Ellusion Logo"
          className="max-sm:h-80 max-sm:w-80 h-96 w-96 animated-image-sm loading lg:hidden"
        />
      </div>
    </div>
  );
};

export default Loading;
