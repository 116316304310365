import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Typewriter = () => {
  const {t} = useTranslation("global");
  const texts = t("homepage.texts", {returnObjects: true});
  const [textIndex, setTextIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");
  const [deleting, setDeleting] = useState(false);
  const [showDots, setShowDots] = useState(false);

  useEffect(() => {
    let timeout;

    if (!deleting) {
      if (displayText.length < texts[textIndex].length) {
        timeout = setTimeout(() => {
          setDisplayText(texts[textIndex].slice(0, displayText.length + 1));
        }, 100);
      } else {
        setShowDots(true);
        timeout = setTimeout(() => {
          setShowDots(false);
          setDeleting(true);
        }, 2000);
      }
    } else {
      if (displayText.length > 0) {
        timeout = setTimeout(() => {
          setDisplayText(texts[textIndex].slice(0, displayText.length - 1));
        }, 100);
      } else {
        setDeleting(false);
        setTextIndex((prev) => (prev + 1) % texts.length);
      }
    }

    return () => clearTimeout(timeout);
  }, [displayText, deleting, textIndex]);

  return (
    <div className="flex flex-col justify-between relative z-10 max-md:text-center ">
      <h1 className=" font-light poppins-light mb-10 sm:text-2xl max-md:pt-0 md:pt-5">{t("homepage.title")}</h1>
      <div className=" max-sm:text-4xl sm:text-5xl md:text-4xl lg:text-6xl w-full min-h-80 max-md:pt-0 md:pt-5">
        {displayText}
        {showDots && <span className="dots text-black ms-1">_</span>}
      </div>
    </div>
  );
};

export default Typewriter;
