import React, { useEffect, useRef, useState } from "react";
import BigCardItem from './BigCardItem';
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const CompanySection = () => {
  const { t } = useTranslation("global");
  const containerRef = useRef(null);
  const data = t("homepage.reference.referenceItems", { returnObjects: true });

  useEffect(() => {
    gsap.set(".my-projects", {
      opacity: 1,
    });

    gsap.to(".my-projects", {
      opacity: 0.4,
      scrollTrigger: {
        trigger: ".my-projects",
        start: "bottom 30%",
        end: "top 70%",
        scrub: true,
      },
    });
  }, []);

  return (
    <div
      className="my-projects max-lg:px-8 lg:px-10 xl:px-32  relative z-10 transition-all duration-300"
      style={{ scrollbarWidth: "thin" }}
    >
      <div className=" mb-20 mt-5 text-center">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl">{t("homepage.reference.title")}</h1>
        <p className="text-lg md:text-xl lg:text-2xl font-light mt-5">
          {t("homepage.reference.desc")}
        </p>
      </div>

      <div
        className="grid md:grid-cols-2 lg:grid-cols-3 max-md:grid-cols-1 gap-10 "
        ref={containerRef}
      >
        {data?.map((item, index) => (
          <BigCardItem item={item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default CompanySection;
