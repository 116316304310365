import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ScrollTextScreen = ({ desc }) => {
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const textElement = textRef.current;
      const text = textElement.textContent;

      const modifiedText = text
        .split("")
        .map(
          (char, index) =>
            `<span class="letter" data-index="${index}">${char}</span>`
        )
        .join("");

      textElement.innerHTML = modifiedText;

      // GSAP animasyonu her harf için tetikleyecek
      gsap.to(".letter", {
        color: "#fff",
        duration: 0.5,
        stagger: 0.05, // Harfler sırayla yanacak
        scrollTrigger: {
          trigger: textElement,
          start: "top 90%",
          end: "bottom top",
          scrub: true, // Kaydırma ile orantılı ilerleme
        },
      });
    }
  }, [desc]); // desc değiştikçe animasyonu tetikle

  return (
    <div className="h-screen relative">
      <div className="sticky top-0 left-0 flex items-center justify-center h-screen text-[#202126]">
        <div
          className="scroll-text max-sm:text-2xl max-xl:text-4xl xl:text-6xl text-center"
          ref={textRef}
        >
          {desc}
        </div>
      </div>
    </div>
  );
};

export default ScrollTextScreen;
