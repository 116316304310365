import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
import Typewriter from "./TypeWriter";
import OldMainScreen from "./OldMainScreen";
gsap.registerPlugin(ScrollTrigger);

const MainScreen = () => {
  const { t } = useTranslation("global");

  useEffect(() => {
    gsap.fromTo(
      ".laptop-device",
      {
        translateX: 0,
      },
      {
        translateX: 50,
        scrollTrigger: {
          trigger: ".wrapper",
          start: "top 80%",
          end: "center center",
          scrub: true,
        },
      }
    );

    gsap.set(".device-img", {
      perspective: "8000px", // Perspektifi sabit tut
      rotateX: "15deg",
      rotateY: "-8deg",
      rotateZ: "18deg",
      width: "61%",
      height: "52%",
    });

    gsap.to(".all-wrapper", {
      opacity: ".7",
      scrollTrigger: {
        trigger: ".wrapper",
        start: "top 80%",
        end: "center center",
        scrub: true,
      },
    });

    const animation = gsap.to(".device-img", {
      perspective: "0px",
      rotateX: "0deg",
      rotateY: "0deg",
      rotateZ: "0deg",
      width: "150%",
      height: "150%",
      borderRadius: "50px",
      x:
        window.innerWidth >= 2560
          ? "-160%"
          : window.innerWidth >= 1920
          ? "-114%"
          : window.innerWidth >= 1440
          ? "-90%"
          : "0%",

      y: "-30%",
      ease: "power1.out",
      scrollTrigger: {
        trigger: ".wrapper",
        start: "top 80%",
        end: "center center",
        scrub: true,
      },
    });

    return () => animation.kill();
  }, []);

  return (
    <>
      <div className="relative min-h-[300vh] max-xl:hidden">
        {/* Arka Plan */}
        <div className="sticky top-0 w-full h-screen z-0">
          <img
            src="/images/home4.jpg"
            alt="Background"
            className="w-full h-full object-cover"
          />
          <div className="absolute top-0 w-full h-screen z-0">
            <div className="absolute bg-black w-full h-screen opacity-0 z-20 all-wrapper" />
            <div className="relative flex md:justify-between md:items-center gap-32 md:h-1/2 xl:h-screen w-full md:px-10 2xl:pe-32 2xl:ps-48">
              {/* TEXT */}
              <Typewriter />

              {/* DEVICE */}
              <div className="flex justify-center items-center max-md:-mt-16 max-md:mb-28">
                <div className="relative max-md:w-full md:w-[300px] xl:w-[500px]">
                  {/* Laptop Arka Planı */}
                  <img
                    src="/images/device-bg.png"
                    alt="Laptop"
                    className="w-full h-full laptop-device"
                  />

                  {/* Ekran İçeriği */}
                  <div
                    className="device-img absolute top-[12%] left-[32%] overflow-hidden z-30"
                    style={{
                      transform:
                        "perspective(5000px) rotateX(15deg) rotateY(-8deg) rotateZ(21deg)",
                    }}
                  >
                    <video
                      className="w-full h-full object-cover"
                      autoPlay
                      loop
                      muted
                    >
                      <source src="/video/device_video.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>

              {/* MOUSE */}
              <div className="absolute bottom-10 left-0 flex justify-center items-center w-full gap-2 group z-20 cursor-pointer">
                <div className="h-8 w-4 border border-[#000] group-hover:border-gray-700 transition-all duration-300 rounded-full relative">
                  <div className="absolute top-0 left-0 w-full flex justify-center items-center">
                    <span className="mouse transition-all duration-300 h-2 bg-[#000] group-hover:bg-gray-700 w-[2px] rounded-full" />
                  </div>
                </div>
                <p className="text-[#000] group-hover:bg-gray-700 transition-all duration-300">
                  {t("homepage.mouseText")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative z-10 text-black px-8 py-20">
          {/* Sayfayı uzatmak için ekstra içerik */}
          <div className="h-[200vh] wrapper"></div>
        </div>
      </div>

      <OldMainScreen />
    </>
  );
};

export default MainScreen;
