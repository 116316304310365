import { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

const Slider = () => {
  const [offset, setOffset] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const requestRef = useRef(null);

  const data = [
    {
      title: "Logo Tasarım",
      desc: "Markanızı en iyi şekilde yansıtacak özgün ve akılda kalıcı logo tasarımları ile güçlü bir kimlik oluşturuyoruz.",
      icon: "ri-pen-nib-line",
      image: "",
    },
    {
      title: "Kurumsal Kimlik",
      desc: "Firmanızın vizyonunu ve değerlerini en iyi şekilde yansıtan profesyonel kurumsal kimlik tasarımlarıyla markanızı güçlendiriyoruz.",
      icon: "ri-profile-line",
      image: "",
    },
    {
      title: "Web Yazılım",
      desc: "Özel ve yenilikçi web yazılımlarıyla dijital dünyada fark yaratmanızı sağlıyoruz.",
      icon: "ri-code-s-slash-fill",
      image: "",
    },
    {
      title: "Mobil Uygulama",
      desc: "hızlı ve kullanıcı dostu mobil uygulamalar geliştirerek dijital dünyada güçlü bir yer edinmenizi sağlıyoruz.",
      icon: "ri-smartphone-line",
      image: "",
    },
    {
      title: "Video Prodüksiyon",
      desc: "Yaratıcı ve etkileyici video prodüksiyon hizmetlerimizle markanızı en iyi şekilde görselleştiriyoruz.",
      icon: "ri-vidicon-line",
      image: "",
    },
    {
      title: "Sosyal Medya Reklamları",
      desc: "Etkili sosyal medya reklamlarıyla markanızın görünürlüğünü artırıyor, hedef kitlenize ulaşarak dijital başarı sağlıyoruz.",
      icon: "ri-megaphone-line",
      image: "",
    },
    {
      title: "Sosyal Medya Yönetimi",
      desc: "Markanızın sosyal medya hesaplarını etkin bir şekilde yöneterek, etkileşimleri artırıyor ve dijital varlığınızı güçlendiriyoruz.",
      icon: "ri-group-line",
      image: "",
    },
  ];

  useEffect(() => {
    const handleMove = (clientX) => {
      if (!isActive) return;

      const screenWidth = window.innerWidth;
      const baseOffset =
        screenWidth *
        (window?.innerWidth >= 1440
          ? 0.9
          : window?.innerWidth >= 1280
          ? 1.5
          : window?.innerWidth >= 1024
          ? 1.6
          : 0.8); // Ekran genişliğine göre kaydırma mesafesi
      const moveAmount = (clientX / screenWidth) * baseOffset;

      cancelAnimationFrame(requestRef.current);
      requestRef.current = requestAnimationFrame(() => setOffset(-moveAmount));
    };

    const handleMouseMove = (e) => handleMove(e.clientX);

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      cancelAnimationFrame(requestRef.current);
    };
  }, [isActive]);

  return (
    <div className="relative w-full sm:min-h-screen pt-16 ">
      {/* <div className="absolute top-0 left-0 w-full h-20 bg-gradient-to-b to-transparent from-[#090b10]"/> */}

      {/* text content */}
      <div className="relative z-10 text-center pb-20 px-8 md:px-32 md:-pb-0 mt-10">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
          Hizmetlerimizi Keşfedin
        </h1>
        <p className="text-lg sm:text-xl md:text-2xl font-light mt-2">
          Dijital dünyada sınırları kaldırıyoruz! İşletmenizin her ihtiyacına
          özel çözümler sunarak, <br /> sizi geleceğe taşıyan yenilikçi
          hizmetlerle yanınızdayız.
        </p>
      </div>

      {/* cards for pc */}
      <div
        className="relative z-20 overflow-hidden w-full  flex items-center mouse-slider-container-pc"
        onMouseEnter={() => setIsActive(true)}
        onMouseLeave={() => setIsActive(false)}
      >
        <div
          className="relative py-32 flex transition-transform duration-500 ease-out"
          style={{ transform: `translateX(${offset}px)` }}
        >
          {data?.map((item, index) => (
            <div key={index} className="w-[300px] md:w-[450px] pe-4 bg-opacity-25 h-[450px] rounded-3xl">
              <div className="relative z-20 rounded-3xl group w-full h-full">
                {/* image */}
                <div className="w-full h-full object-cover rounded-3xl bg-black/75" />
                {/* icon */}
                <div className="absolute bottom-[25%] group-hover:bottom-[55%] transition-all duration-300 left-0 p-4 z-20 text-white">
                  <div className="w-16 h-16 rounded-full bg-[#090B10]/75 flex justify-center items-center">
                    <i className={`${item?.icon} text-2xl`} />
                  </div>
                </div>
                {/* title */}
                <div className="absolute bottom-[10%] group-hover:bottom-[45%] transition-all duration-300 left-0 p-4 z-20 text-white">
                  <h1 className="text-xl md:text-2xl">{item?.title}</h1>
                </div>
                {/* desc */}
                <div className="absolute bottom-[10%] group-hover:bottom-[25%] opacity-0 group-hover:opacity-100 transition-all duration-300 left-0 p-4 z-20 text-white">
                  {item?.desc}
                </div>
                {/* gradient */}
                <div className="absolute bottom-0 left-0 w-full z-10">
                  <div className="bg-gradient-to-t from-[#090B10] to-transparent h-20 w-full rounded-b-3xl"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* cards for mobile */}
      <div className="mouse-slider-container-mobile">
        <div className="h-full w-full relative z-30 pb-20 px-8">
          <Swiper
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="relative z-20 rounded-3xl group">
                  <img
                    src="/images/red.png"
                    alt=""
                    className="w-full h-full object-cover rounded-3xl"
                  />

                  <div className="absolute bottom-[10%] transition-all duration-300 left-0 p-4 z-20 text-white flex flex-col gap-2">
                    <div className="w-16 h-16 rounded-full bg-[#090B10]/75 flex justify-center items-center max-sm:w-10 max-sm:h-10 max-md:w-14 max-md:h-14">
                      <i className={`${item?.icon} text-2xl`} />
                    </div>
                    <h1 className="max-sm:text-lg text-xl md:text-2xl">
                      {item?.title}
                    </h1>
                    <p className="max-sm:text-sm font-light">{item?.desc}</p>
                  </div>

                  <div className="absolute bottom-0 left-0 w-full z-10 ">
                    <div className="bg-gradient-to-t from-[#090B10] to-transparent h-20 w-full rounded-b-3xl" />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="">
            <div className="absolute bottom-10 right-32">
              <button className="max-sm:w-10 max-sm:h-10 max-md:w-14 max-md:h-14 w-16 h-16 rounded-full bg-[#202126] border border-[#595b64] transition-all duration-300 hover:border-white swiper-button-prev group">
                <i class="ri-arrow-right-line text-xl text-[#595b64] group-hover:text-white transition-all duration-300"></i>
              </button>
            </div>

            <div className="absolute bottom-10 left-32">
              <button className="max-sm:w-10 max-sm:h-10 max-md:w-14 max-md:h-14 w-16 h-16 rounded-full bg-[#202126] border border-[#595b64] transition-all duration-300 hover:border-white swiper-button-next group">
                <i class="ri-arrow-left-line text-xl text-[#595b64] group-hover:text-white transition-all duration-300"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
