import React, { useEffect, useState, useRef } from "react";
import Breadcrumb from "../components/layouts/Breadcrumb";
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollTextScreen from "../components/layouts/ScrollTextScreen";
import { useParams } from "react-router-dom";
import Footer from "../components/layouts/Footer";
import { useTranslation } from "react-i18next";
import Aurora from "../components/layouts/Aurora";
import FallingText from "../components/layouts/BallPool";
import BallPool from "../components/layouts/BallPool";
import BallPoolDeveloper from "../components/layouts/BallPoolDeveloper";
gsap.registerPlugin(ScrollTrigger);

const WorkPage = () => {
  const [activeAccordion, setActiveAccordion] = useState(1);
  const [work, setWork] = useState({});
  const { slug } = useParams();

  const { t } = useTranslation("global");

  const cardItems = [
    {
      text: "kart ürünü 1",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis sit, error nesciunt quisquam dignissimos consequuntur!",
      image: "/images/card-img2.png",
    },
    {
      text: "kart ürünü 2",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis sit, error nesciunt quisquam dignissimos consequuntur!",
      image: "",
    },
    {
      text: "kart ürünü 3",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis sit, error nesciunt quisquam dignissimos consequuntur!",
      image: "/images/card-img2.png",
    },
    {
      text: "kart ürünü 4",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis sit, error nesciunt quisquam dignissimos consequuntur!",
      image: "",
    },
    {
      text: "kart ürünü 5",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis sit, error nesciunt quisquam dignissimos consequuntur!",
      image: "/images/card-img2.png",
    },
    {
      text: "kart ürünü 6",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis sit, error nesciunt quisquam dignissimos consequuntur!",
      image: "",
    },
  ];
  const listItems = [
    {
      title: "BAŞLIK 1",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 2",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 3",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 4",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 5",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 6",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 7",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
    {
      title: "BAŞLIK 8",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis eos hic nemo labore expedita impedit ipsam.",
    },
  ];

  useEffect(() => {
    if (t("workPage")) {
      setWork(
        t("workPage", { returnObjects: true })?.filter(
          (item) => item.slug === slug
        )[0]
      );
    }
    const lenis = new Lenis({
      smooth: true,
      lerp: 0.1,
      wheelMultiplier: 0.8,
      touchMultiplier: 1.2,
      infinite: false,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    // GSAP ScrollTrigger ile senkronize et
    ScrollTrigger.scrollerProxy(document.body, {
      scrollTop(value) {
        return arguments.length
          ? lenis.scrollTo(value, { immediate: true })
          : lenis.scroll;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
    });

    // ScrollTrigger'ı güncelle
    lenis.on("scroll", ScrollTrigger.update);
    gsap.to(".work-img", {
      width: "100vw",
      height: "100vh",
      borderRadius: "0px",
      opacity: 0.3,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".work-img",
        start: "top 70%",
        end: "bottom 30%",
        scrub: true,
      },
    });

    ["card-wrapper", "accordion"]?.map((classN, i) => {
      gsap.set(`.${classN}`, {
        opacity: 1,
      });

      gsap.to(`.${classN}`, {
        opacity: 1,
        scrollTrigger: {
          trigger: `.${classN}`,
          start: "bottom 30%",
          end: "top 50%",
          scrub: true,
        },
      });
    });

    gsap.utils.toArray(".card").forEach((card, i) => {
      gsap.fromTo(
        card,
        { opacity: 0, y: 50, scale: 0.8 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "power2.out",
          scrollTrigger: {
            trigger: card,
            start: "top 80%",
            end: "top 50%",
            scrub: false,
            toggleActions: "play none none none",
          },
        }
      );
    });
  }, [cardItems.length]);

  return (
    <>
      <Aurora colorStops={["#231b42", "#634092", "#3b2665"]} speed={2} />
      <div className="relative w-full h-full pt-48">
        {/* main-section */}
        <section>
          {/* background-image */}
          <div className="absolute w-full h-full top-0 left-0">
            <img src="/images/bg-shadow2.webp" alt="" />
          </div>
          {/* title */}
          <div className=" flex flex-col justify-center items-center text-center gap-3">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl">
              {work?.title}
            </h1>
            <h4 className="text-4xl">{work?.subtitle}</h4>
            <Breadcrumb
              paths={[
                { name: "Anasayfa", url: "/" },
                { name: `${work?.title}` },
              ]}
            />
          </div>
          {/* text & image */}
          <div className="h-[140vh] flex justify-center relative mt-32">
            <div className="sticky top-0">
              <img
                src={work?.image}
                alt="Work image"
                className="work-img rounded-3xl max-sm:w-[250px] max-lg:w-[400px] max-lg:h-[400px] lg:lg:w-[550px] lg:h-[550px] transition-all duration-100 opacity-100"
              />
            </div>

            <div className="absolute bottom-0 ">
              <ScrollTextScreen desc={`${work?.desc}`} />
            </div>
          </div>
        </section>

        {/* card section */}
        <section className="card card-wrapper transition-all duration-300 relative max-sm:px-8 sm:px-10 lg:px-16 xl:px-32">
          {/* card bg shadow */}
          <div className="absolute w-full h-full -top-[40rem] left-0 -z-10">
            <img src="/images/bg-shadow.webp" alt="" className="" />
          </div>
          {/* card section title */}
          <div className="flex justify-center items-center flex-col  text-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl">
              {work?.cardSection?.title}
            </h1>
            <p className=" mt-3">{work?.cardSection?.desc}</p>
          </div>

          {/* cards */}
          <div className="grid max-md:grid-cols-1 md:grid-cols-2 gap-6 mt-10">
            {work?.cardSection?.cards?.map((card, i) => (
              <div
                key={i}
                className="card relative h-[40vh] rounded-lg transition-all duration-300 cursor-pointer overflow-hidden hover:scale-95"
              >
                <div className={`absolute right-0 bottom-0`}>
                  <img src={card.image} alt="" className="w-full h-full" />
                </div>
                <div className="bg-gradient-to-br from-[#177299] to-[#177299]/40 absolute w-full h-full top-0 left-0 opacity-50 rounded-lg p-6 border" />
                <div className="p-6 ">
                  <h3 className="text-2xl font-semibold">{card.text}</h3>
                  <p className="font-light text-lg">{card.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <div className="relative max-sm:h-screen sm:min-h-[200vh] ">
          <div className="sticky top-0 w-full max-sm:hidden">
            {slug === "yazilim-gelistirme" ? (
              <BallPoolDeveloper />
            ) : (
              <BallPool />
            )}
          </div>

          <div className="sm:sticky top-0 z-10 md:bg-black min-h-screen h-full w-full py-16">
            <section className="accordion transition-all duration-300 min-h-screen h-full mb-20 max-sm:px-8 sm:px-10 lg:px-16 xl:px-32">
              {/* accoridon section title */}
              <div className="flex justify-center items-center flex-col md:text-white text-center t-20">
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl">
                  {work?.accordionSection?.title}
                </h1>
                <p className="md:text-white text-lg md:text-xl lg:text-2xl font-light mt-2">
                  {work?.accordionSection?.desc}
                </p>
              </div>

              {/* accordion computer */}
              <div className="work-container transition-all duration-300 max-sm:hidden sm:grid max-lg:grid-cols-2 lg:grid-cols-4 mt-32 relative z-10 gap-10  ">
                <div className="absolute bottom-0 left-0 -z-10 w-full h-full max-sm:hidden">
                  <img
                    src="/images/bg-shadow.webp"
                    loading="lazy"
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                {work?.accordionSection?.accordion?.map((item, i) => (
                  <div
                    className="work-item cursor-pointer text-white flex flex-col gap-5 transition-all duration-300"
                    key={i}
                  >
                    <div className="bg-white w-14 h-14 rounded-full ">
                      <i
                        className={`${item.icon} text-black flex justify-center items-center w-full h-full text-2xl`}
                      ></i>
                    </div>

                    <h1 className="text-xl">{item?.title}</h1>
                    <p className="font-light">{item?.desc}</p>
                  </div>
                ))}
              </div>

              {/* accordion mobile */}
              <div className="h-full w-full sm:hidden relative mt-10">
                {/* accordion bg shadow */}
                <div className="absolute top-0 left-0 z-10 w-full h-full overflow-hidden">
                  <img
                    src="/images/bg-shadow.webp"
                    alt=""
                    className="w-full h-full scale-125"
                  />
                </div>
                {/* accordion items */}
                <div className="md:text-white relative z-10">
                  <div className="flex flex-col gap-5">
                    {work?.accordionSection?.accordion?.map((item, i) => (
                      <div key={i}>
                        <div
                          className="flex justify-between border-b max-md:border-[#0c080867] md:border-[#ffffff5b] md:text-[#ffffffb4] cursor-pointer"
                          onClick={() =>
                            setActiveAccordion(
                              activeAccordion === i + 1 ? 0 : i + 1
                            )
                          }
                        >
                          <h1 className="">{item?.title}</h1>
                          <i
                            class={`ri-arrow-down-s-line text-xl transition-all duration-200 ${
                              activeAccordion === i + 1
                                ? "-rotate-180"
                                : "rotate-0"
                            }`}
                          ></i>
                        </div>
                        <div
                          className={`${
                            activeAccordion === i + 1
                              ? "max-h-[1000px] opacity-100" // Daha geniş max-height
                              : "max-h-0 opacity-0"
                          } overflow-hidden transition-all duration-200 ease-in-out`}
                        >
                          <p className="font-light mt-2">{item?.desc}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WorkPage;
