import React, { useState } from "react";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { LuMail } from "react-icons/lu";
import { FaRegMap } from "react-icons/fa6";
import Button from "../layouts/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation("global");

  const footerItems = [
    {
      icon: (
        <i class="ri-customer-service-line max-sm:text-2xl text-[#ffffffa6] "></i>
      ),
      text: "+90 0533 766 20-90",
    },
    {
      icon: <i class="ri-mail-send-line max-sm:text-2xl text-[#ffffffa6]"></i>,
      text: "contact@ellusion.tech",
    },
    {
      icon: <i class="ri-map-pin-line max-sm:text-2xl text-[#ffffffa6]"></i>,
      text: "Beşyol, İnönü Cd. No:38, 34295, 34000 Küçükçekmece/İstanbul",
    },
  ];
  const socialMediaItems = [
    {
      url: "https://www.instagram.com/ellusiontech/",
      icon: "ri-instagram-line",
    },
    {
      url: "https://www.linkedin.com/",
      icon: "ri-linkedin-box-fill",
    },
    {
      url: "https://www.youtube.com/",
      icon: "ri-youtube-fill",
    },
    {
      url: "https://www.x.com/",
      icon: "ri-twitter-x-line",
    },
  ];
  const footerTextItems = t("header", { returnObjects: true });

  const routerHandle = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  return (
    <footer className="max-md:h-[600px] md:h-screen w-full relative z-20">
      <div className="h-10 bg-gradient-to-t from-transparent to-[#0A0A0A] absolute w-full z-30 top-0 left-0 " />

      {/* background */}
      <div className="absolute h-full w-full bottom-0 left-0 z-20 overflow-hidden">
        <div className="absolute w-full h-full top-0 left-0 bg-black/50 z-30" />
        <div className="md:hidden w-full h-full">
          <img
            src="/images/footer-bg.webp"
            alt="Footer image"
            loading="lazy"
            className="h-full w-full"
          />
        </div>
        <div className="h-screen w-full relative">
          <video
            preload="auto"
            className="h-full w-full object-cover absolute top-0 left-0"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src="/video/22.mp4" type="video/mp4" />
          </video>
        </div>
      </div>

      {/* footer-content */}
      <div className="absolute z-30 flex justify-between gap-10 md: max-md:px-5 md:px-10 max-md:flex-col w-full">
        <div className="flex-1 md:flex h-[calc(80vh)]">
          {/* Sol Bölüm */}
          <div className="flex-1 flex justify-between items-start flex-col h-full">
            {/* üst */}
            <div className="h-full">
              <img
                src="/images/logo-beyaz.webp"
                loading="lazy"
                alt="Ellusion Logo"
                className="xl:w-80 xl:h-72 lg:w-64 lg:h-64 md:w-64 md:h-64 max-md:w-64 max-md:h-64 w-32 h-32 max-sm:-mt-10 max-sm:-ms-5 object-cover"
              />
              <div className="md:mt-10 flex flex-col gap-5 items-start justify-center max-sm:-mt-10">
                {footerItems?.map((item, i) => (
                  <div
                    className="flex items-center gap-2 max-sm:text-lg max-md:text-xl text-3xl"
                    key={i}
                  >
                    <span>{item?.icon}</span>
                    <p className="text text-[#ffffffa6]">{item?.text}</p>
                  </div>
                ))}
                <Button
                  className="text-white px-6 py-3 rounded-full transition mt-2"
                  text={"Yol Tarifi Al"}
                  style={{ height: "" }}
                  onClick={() =>
                    (window.location.href =
                      "https://www.google.com/maps/place/Ellusion+Technology/@40.9941194,28.79359,19z/data=!4m6!3m5!1s0x14caa37b7c105081:0x2fa6c647ba2e494b!8m2!3d40.9928319!4d28.7976884!16s%2Fg%2F11tf4zc6cb?entry=ttu&g_ep=EgoyMDI1MDIwMy4wIKXMDSoASAFQAw%3D%3D")
                  }
                />
              </div>
            </div>

            {/* alt */}
            <div className="text-[#ffffffa6] max-md:hidden flex flex-col gap-5">
              <div className="flex gap-5">
                <a
                  href="/cerez-politikasi"
                  className="hover:text-white transition-all duration-300"
                >
                  Çerez Politikası
                </a>
                <a
                  href="/kisisel-verilerin-korunmasi"
                  className="hover:text-white transition-all duration-300"
                >
                  KVKK
                </a>
                <a
                  href="/gizlilik-politikasi"
                  className="hover:text-white transition-all duration-300"
                >
                  Gizlilik Politikası
                </a>
              </div>
              <div className="">
                ©
                {
                  new Date(Date.now())
                    .toLocaleDateString({ minimize: true })
                    .split(".")[2]
                }{" "}
                Ellusion tüm hakları saklıdır.
              </div>
            </div>
          </div>

          {/* Sağ Bölüm */}
          <div className="flex-1 flex justify-between items-end max-md:gap-2 gap-10 flex-col max-md:hidden md:mt-10">
            <div className={`flex flex-col gap-10 items-end`}>
              {footerTextItems.map((item, index) => (
                <>
                  <div className={`${isActive ? "hidden" : "block"}`}>
                    <h1
                      key={index}
                      onClick={() => {
                        !item?.dropdown && routerHandle(item?.url);
                        item?.dropdown && setIsActive(true);
                      }}
                      className="text-[#848588] max-lg:text-4xl lg:text-6xl cursor-pointer hover:text-white transition-all duration-300 group"
                    >
                      {item?.dropdown && <i class="ri-arrow-left-line" />}{" "}
                      {item?.text}
                    </h1>
                  </div>

                  <div
                    className={`flex flex-col gap-10 items-end text-end ${
                      !isActive ? "hidden" : "block"
                    }`}
                  >
                    {item?.dropdownItems?.map((item, index) => (
                      <h1
                        key={index}
                        onClick={() => {
                          window.location.href = `/hizmetler${item?.url}`
                          setIsActive(true);
                        }}
                        className="text-[#848588] max-lg:text-4xl lg:text-5xl xl:text-6xl cursor-pointer hover:text-white transition-all duration-300"
                      >
                        {item?.name}
                      </h1>
                    ))}
                  </div>
                </>
              ))}

              <h1
                onClick={() => {
                  setIsActive(false);
                }}
                className={`text-[#848588] max-lg:text-4xl lg:text-5xl xl:text-6xl cursor-pointer hover:text-white transition-all duration-300 ${
                  !isActive ? "hidden" : "block"
                }`}
              >
                <i class="ri-arrow-left-line" /> Geri
              </h1>
            </div>
            <div className="flex gap-2 items-center max-md:mt-10">
              {socialMediaItems?.map((item, i) => (
                <div className="" key={i}>
                  <a href={item?.url} target="_blank">
                    <i
                      className={`${item?.icon} text-[#ffffffa6] max-sm:text-xl text-3xl hover:text-white transition-all duration-300`}
                    ></i>
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className="flex gap-2 items-center max-md:mt-10 md:hidden">
            {socialMediaItems?.map((item, i) => (
              <div className="" key={i}>
                <a href={item?.url} target="_blank">
                  <i
                    className={`${item?.icon} text-[#ffffffa6] max-md:text-2xl hover:text-white transition-all duration-300`}
                  ></i>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="absolute z-30 bottom-0 w-full text-center text-[#ffffffa6] md:hidden">
        <div className="flex items-center justify-center gap-2 px-3 mb-2">
          <a
            href="/cerez-politikasi"
            className="hover:text-white transition-all duration-300"
          >
            Çerez Politikası
          </a>
          <a
            href="/kisisel-verilerin-korunmasi"
            className="hover:text-white transition-all duration-300"
          >
            KVKK
          </a>
          <a
            href="/gizlilik-politikasi"
            className="hover:text-white transition-all duration-300"
          >
            Gizlilik Politikası
          </a>
        </div>
        <div className="">
          ©
          {
            new Date(Date.now())
              .toLocaleDateString({ minimize: true })
              .split(".")[2]
          }{" "}
          Ellusion tüm hakları saklıdır.
        </div>
      </div>
      <div className="absolute bg-gradient-to-t from-black to-transparent bottom-0 left-0 h-64 md:h-96 z-20 w-full" />
    </footer>
  );
};

export default Footer;
