import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const CounterSection = () => {
  const { t } = useTranslation("global");

  const topLeftRef = useRef(null);
  const topRightRef = useRef(null);
  const bottomLeftRef = useRef(null);
  const bottomRightRef = useRef(null);

  const data = t("homepage.world.worldItems", { returnObjects: true });

  useEffect(() => {
    gsap.set(`.world`, {
      opacity: 1,
    });

    gsap.to(`.world`, {
      opacity: 0.4,
      scrollTrigger: {
        trigger: `.world`,
        start: "bottom 30%",
        end: "top 50%",
        scrub: true,
      },
    });

    data?.map((item, i) => {
      gsap.fromTo(
        i === 0
          ? topLeftRef.current
          : i === 1
          ? topRightRef.current
          : i === 2
          ? bottomLeftRef.current
          : bottomRightRef.current,
        { innerHTML: 0 },
        {
          innerHTML: item.count,
          duration: 2.5,
          ease: "power1.out",
          roundProps: "innerHTML", // Tam sayı olarak değiştirmek için
          scrollTrigger: {
            trigger: ".world",
            start: "top 60%",
            toggleActions: "play none none reset",
          },
        }
      );
    });
  }, []);

  return (
    <div className="flex">
      <div className="world transition-all duration-300 relative min-h-[80vh] h-full w-full flex items-center max-xl:px-8 overflow-hidden max-sm:-mt-32 bg-[#F0F1FA]">
        {/* world background */}
        <div className="absolute top-0 right-0 w-full h-full overflow-hidden">
          <img
            src="/images/world.jpg"
            alt=""
            className="scale-125 blur-top h-full w-full object-cover"
          />
        </div>

        {/* world text */}
        <div className=" relative z-10 flex flex-col justify-start pb-10 pt-10 ">
          <div className="xl:ps-32">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl w-full sm:font-light">
              {t("homepage.world.title")}
            </h1>
            <p className="max-sm:text-xl max-sm:font-light md:text-2xl xl:text-3xl sm:font-extralight mt-5 sm:w-2/5">
              {t("homepage.world.desc")}
            </p>
          </div>

          {/* Counter */}
          <div className="grid grid-cols-2 gap-10 max-xl:mt-10 xl:mb-10 xl:mt-10 xl:px-32">
            <div className="flex max-md:flex-col lg:items-center xl:justify-start md:gap-4">
              <div className="flex">
                <p
                  className="max-lg:text-5xl lg:text-7xl text-9xl top-left"
                  ref={topLeftRef}
                >
                  {t("homepage.world.worldItems.0.count")}
                </p>
                <span className="max-lg:text-5xl lg:text-7xl text-9xl top-left">
                  {t("homepage.world.worldItems.0.symbol")}
                </span>
              </div>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: t("homepage.world.worldItems.0.text"),
                }}
              />
            </div>
            <div className="flex max-md:flex-col lg:items-center xl:justify-start md:gap-4">
              <div className="flex">
                <p
                  className="max-lg:text-5xl lg:text-7xl text-9xl"
                  ref={topRightRef}
                >
                  {t("homepage.world.worldItems.1.count")}
                </p>
                <span className="max-lg:text-5xl lg:text-7xl text-9xl">
                  {t("homepage.world.worldItems.1.symbol")}
                </span>
              </div>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: t("homepage.world.worldItems.1.text"),
                }}
              />
            </div>
          </div>

          <div className="xl:hidden w-5/6 h-[1px] bg-[#ffffff80] mt-10 sm:mb-10" />

          <div className="grid grid-cols-2 gap-10 max-sm:mt-10 xl:px-32">
            <div className="flex max-md:flex-col lg:items-center xl:justify-start md:gap-4">
              <div className="flex">
                <p
                  className="max-lg:text-5xl lg:text-7xl text-9xl"
                  ref={bottomLeftRef}
                >
                  {t("homepage.world.worldItems.2.count")}
                </p>
                <span className="max-lg:text-5xl lg:text-7xl text-9xl">
                  {t("homepage.world.worldItems.2.symbol")}
                </span>
              </div>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: t("homepage.world.worldItems.2.text"),
                }}
              />
            </div>
            <div className="flex max-md:flex-col lg:items-center xl:justify-start md:gap-4">
              <div className="flex ">
                <p
                  className="max-lg:text-5xl lg:text-7xl text-9xl"
                  ref={bottomRightRef}
                >
                  {t("homepage.world.worldItems.3.count")}
                </p>
                <span className="max-lg:text-5xl lg:text-7xl text-9xl">
                  {t("homepage.world.worldItems.3.symbol")}
                </span>
              </div>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: t("homepage.world.worldItems.3.text"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">
      </div>
    </div>
  );
};

export default CounterSection;
