import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);

const LogoSection = () => {
  const [activeLogo, setActiveLogo] = useState(0);
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  let settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const logoItems = [
    {
      img: "/images/logo/adidas-logo.png",
      className: "bottom-10 left-[5%]",
    },
    {
      img: "/images/logo/sephora-logo.png",
      className: "bottom-10 left-[25%]",
    },
    {
      img: "/images/logo/aston-martin-logo.png",
      className: " bottom-48 left-[15%]",
    },
    {
      img: "/images/logo/hilton-logo.png",
      className: "bottom-10 right-[5%]",
    },
    {
      img: "/images/logo/migros-logo.png",
      className: "bottom-10 right-[25%]",
    },
    {
      img: "/images/logo/redbull-logo.png",
      className: "bottom-48 right-[15%]",
    },
    {
      img: "/images/logo/mendels-logo.png",
      className: "bottom-64 right-[5%]",
    },
    {
      img: "/images/logo/metafour-logo.png",
      className: "bottom-64 left-[5%]",
    },
    { img: "/images/logo/taff-logo.png", className: "bottom-48 right-[46%]" },
  ];
  const routeHandle = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveLogo((prev) => (prev >= 8 ? 0 : prev + 1));
    }, 1000);

    logoItems?.map((item, index) => {
      gsap.set("story", {
        opacity: 1,
      });

      gsap.to(".story", {
        opacity: 0.4,
        scrollTrigger: {
          trigger: `.story`,
          start: "top -30%",
          end: "bottom 10%",
          scrub: true,
          toggleActions: "restart pause resume reverse",
        },
      });
    });

    gsap.set("story", {
      opacity: 1,
    });

    gsap.to("story", {
      opacity: 0.4,
      scrollTrigger: {
        trigger: "story",
        start: "bottom 30%",
        end: "top 70%",
        scrub: true,
      },
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="story transition-all duration-300 relative z-10 text-center min-h-screen h-full flex justify-center items-center overflow-hidden max-md:px-8 max-sm:mt-16">
      <div className="absolute top-0 left-52 flex justify-start items-start w-full h-full blur-md">
        <div className=" w-20 h-20 rounded-full shadow-2xl shadow-purple-500 " />
      </div>
      {/* logolar */}
      <div className="max-lg:hidden absolute w-full h-full">
        {logoItems?.map((item, index) => (
          <div
            className={`${
              item?.className
            } absolute z-30 text-3xl transition-all duration-500 ${
              activeLogo === index ? "opacity-100" : "opacity-50"
            }`}
          >
            <img
              src={item?.img}
              alt=""
              className={`w-36 h-36 ${
                [6, 7].includes(index) && "lg:w-48 lg:h-48"
              } `}
            />
          </div>
        ))}
      </div>
      <div className="absolute max-md:px-8 z-40 -mt-32">
        {/* text */}
        <div className="max-md:px-8 relative z-10 xl:px-32">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
            {t("homepage.story.title")}
          </h1>
          <h4
            className="text-lg sm:text-xl md:text-2xl lg:text-3xl mt-8"
            dangerouslySetInnerHTML={{ __html: t("homepage.story.subtitle") }}
          />
          <p
            className="mt-10 lg:text-xl"
            dangerouslySetInnerHTML={{ __html: t("homepage.story.desc") }}
          />
          <div className="">
          <Button
            text={"Referanslar"}
            className={"mt-10 h-16 w-40"}
            magnet={true}
            color={"white"}
            onClick={() => {
              routeHandle("/referanslar");
              window.scrollTo(0, 0);
            }}
            style={4}
          />
          </div>
        </div>

        {/* logo-slider */}
        <div className="w-screen h-full mt-10 lg:hidden ">
          <Slider {...settings}>
            {logoItems?.map((item, index) => (
              <img
                src={item?.img}
                key={index}
                alt="logo"
                className="w-20 h-20 md:h-32 md:w-full object-cover"
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default LogoSection;
