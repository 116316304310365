import React, { useEffect, useState } from "react";
import MagnetButton from "./MagnetButton";

const Button = ({
  text,
  className,
  onClick,
  stylesheet,
  color,
  style,
  magnet,
}) => {
  const [buttonStyle, setButtonStyle] = useState(style || 1);

  useEffect(() => {
    if (style === undefined) {
      setButtonStyle(1);
    }
  }, [style]);
  return (
    <>
      {buttonStyle === 1 && (
        <button
          className={`border border-[#ffffff80] bg-gradient-to-l ${
            color === "purple" && "to-[#541A99] from-[#3834B7] text-white"
          } py-3 px-4 rounded-full ${className}`}
          style={stylesheet}
          onClick={onClick}
        >
          {text}
        </button>
      )}
      {buttonStyle === 2 && (
        <MagnetButton disabled={magnet ? false : true} padding={50}>
          <button
            className={`relative px-6 py-3 font-bold rounded-lg bg-gradient-to-r ${className} ${
              color === "purple" && "from-purple-500 to-pink-500 text-white"
            } ${
              color === "white" && "from-[#FEFEFE] to-[#E1E0EE] text-black"
            } group`}
            onClick={onClick}
          >
            <span
              className={`absolute group-hover:inset-0 inset-2 transition-all duration-300 bg-gradient-to-r ${
                color === "purple" && "from-purple-500 to-pink-500"
              } ${
                color === "white" && "from-[#FEFEFE] to-[#E1E0EE]"
              } blur-md opacity-75`}
            ></span>
            <span className="relative">{text}</span>
          </button>
        </MagnetButton>
      )}
      {buttonStyle === 3 && (
        <MagnetButton disabled={magnet ? false : true} padding={50}>
          <button className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
            <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />
            <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-white px-5 py-5 text-sm font-medium backdrop-blur-3xl">
            {text}
            </span>
          </button>
        </MagnetButton>
      )}
      {buttonStyle === 4 && (
        <MagnetButton disabled={magnet ? false : true} padding={50}>
          <button
            className={`relative group ${className} overflow-hidden rounded-xl border border-black hover:text-white transition-all duration-300`}
          >
            <div className="absolute -bottom-64 group-hover:-bottom-32 left-0 w-full h-64  rounded-full bg-black transition-all duration-300 -z-[1]"></div>
            {text}
          </button>
        </MagnetButton>
      )}
    </>
  );
};

export default Button;
