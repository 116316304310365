import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import Cookies from "js-cookie";

const Cookie = () => {
  const [isCookieAccepted, setIsCookieAccepted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSettings, setSelectedSettings] = useState({
    performance: false,
    statistic: false,
    marketing: true
  });

  const menuRef = useRef(null);

  const setCookieHandle = () => {
    setIsCookieAccepted(true);
    Cookies.set("cookie", "accepted");
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (typeof Cookies.get("cookie") != undefined && Cookies.get("cookie") === "accepted") {
      setIsCookieAccepted(true);
    } else {
      setIsCookieAccepted(false);
    }

    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; // Sayfa hareketini tamamen durdur
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "static"; // Sayfayı eski haline getir
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto"; // Cleanup işlemi
      document.body.style.position = "static";
    };
  }, [Cookies.get("cookie")]);

  return (
    <div
      className={`fixed bottom-0 left-0 flex justify-center items-end w-full pb-10 h-screen ${
        isCookieAccepted ? "hidden" : "block z-50"
      }`}
    >
      {/* settings */}
      <div
        className={`absolute z-50 w-full h-full top-0 left-0 flex justify-center items-center bg-black bg-opacity-90  ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div
          className="relative max-md:w-full max-md:mx-5 w-2/4 h-2/4 max-sm:h-3/5 bg-[#090b10] rounded-2xl cursor-pointer border bg-opacity-90 "
          ref={menuRef}
        >
          {/* background image */}
          <div className="absolute -top-0 left-0 w-full h-full overflow-hidden z-10">
            <img
              src="/images/bg-shadow.webp"
              alt=""
              className="scale-150 w-full h-full"
            />
          </div>
          {/* close button */}
          <div className="flex justify-center -mt-8 absolute w-full h-full z-10">
            <div
              className="w-16 h-16 bg-[#090b10] cursor-pointer text-white border rounded-full flex items-center justify-center"
              onClick={() => setIsOpen(false)}
            >
              X
            </div>
          </div>

          {/* content */}
          <div className="relative z-10 flex justify-center flex-col mt-10 max-lg:px-5 px-20">
            {/* title */}
            <div className="border-b w-full flex justify-between items-center pb-5 sm:px-5">
              <h1 className="text-white text-xl max-sm:text-sm">Çerez Tercihleri</h1>
              <div className="bg-gradient-to-b from-black/75 to-gray-800 py-2 px-4 rounded-full text-green-500 max-sm:text-sm text-center font-light">
                Her zaman aktif
              </div>
            </div>

            {/* inputs */}
            <div className="mt-10 flex justify-center flex-col gap-8 sm:px-5 border-b pb-10 lg:text-lg">
              <div className="flex justify-between items-center text-white">
                <h1>Performans Çerezleri</h1>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" onClick={() => setSelectedSettings({...selectedSettings, performance: !selectedSettings.performance})} />
                  <div className={`w-12 h-6 peer-checked:bg-green-500 ${selectedSettings?.performance ? "bg-green-500" : "bg-gray-300"} sm:hover:bg-green-500 rounded-full relative transition-all`}>
                    <div className={`w-5 h-5 bg-white rounded-full absolute top-[2px] ${selectedSettings?.performance ? "left-6" : "left-1"} transition-all`}></div>
                  </div>
                </label>
              </div>

              <div className="flex justify-between items-center text-white">
                <h1>İstatistik Çerezleri</h1>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only peer" onClick={() => setSelectedSettings({...selectedSettings, statistic: !selectedSettings.statistic})} />
                  <div className={`w-12 h-6 bg-gray-300 peer-checked:bg-green-500 ${selectedSettings?.statistic ? "bg-green-500" : "bg-gray-300"} sm:hover:bg-green-500 rounded-full relative transition-all`}>
                    <div className={`w-5 h-5 bg-white rounded-full absolute top-[2px] ${selectedSettings?.statistic ? "left-6" : "left-1"} transition-all`}></div>
                  </div>
                </label>
              </div>

              <div className="flex justify-between items-center text-white">
                <h1>Pazarlama Çerezleri</h1>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" className="sr-only" value={selectedSettings.marketing} onClick={() => setSelectedSettings({...selectedSettings, marketing: !selectedSettings.marketing})} />
                  <div className={`w-12 h-6 bg-gray-300 ${selectedSettings?.marketing ? "bg-green-500" : "bg-gray-300"} sm:hover:bg-green-500 rounded-full relative transition-all`}>
                    <div className={`w-5 h-5 bg-white rounded-full absolute top-[2px] ${selectedSettings?.marketing ? "left-6" : "left-1"} transition-all`}></div>
                  </div>
                </label>
              </div>
            </div>

            {/* save button */}
            <div className="flex justify-center items-center mt-10 lg:mt-7">
              <Button className={"w-full shadow-[0_0_120px_2px_rgba(170,0,255,0.8)]"} style={1} color={"purple"}  text={"Ayarları Kaydet"} onClick={() => setIsOpen(false)} />
            </div>
          </div>
        </div>
      </div>

      {/* cookie */}
      <div
        className={`relative max-sm:w-4/5 sm:w-3/4 xl:w-2/5 rounded-xl max-md:py-5 md:rounded-full md:h-16 xl:h-28 bg-[#090b10] border border-[#ffffff80] overflow-hidden ${
          isOpen ? "hidden" : "block"
        }`}
      >
        <div className="absolute z-10 w-full h-full">
          <img
            src="/images/cookie-bg.webp"
            alt=""
            className="w-full h-full scale-125"
          />
        </div>

        <div className="relative z-10 text-white flex max-md:flex-col justify-between items-center max-sm:gap-5 gap-10 w-full h-full px-10">
          <div className="flex gap-5 items-center">
            <img src="/images/cookie.png" alt="" className="w-8 h-8" />
            <p className="max-sm:font-light max-sm:text-sm">
              Sizlere daha iyi bir deneyim sunabilmek amacıyla çerezleri
              kullanıyoruz.
            </p>
          </div>
          <div className="flex gap-5 items-center">
            <a
              className="underline underline-offset-4 cursor-pointer"
              onClick={() => setIsOpen(true)}
            >
              Tercihleri Göster
            </a>
            <Button className={""} text={"Anladım"} onClick={setCookieHandle} style={1} color={"purple"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
