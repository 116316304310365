import React, { useEffect, useState } from "react";
import { MdOutlineTextDecrease, MdOutlineTextIncrease } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/layouts/Breadcrumb";
import { useTranslation } from "react-i18next";
import Footer from "../components/layouts/Footer";
const BlogDetails = () => {
  const [blogData, setBlogData] = useState({});
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [fontSize, setFontSize] = useState({
    title: "max-md:text-3xl text-5xl",
    desc: "text-xl",
    number: 3,
  });
  const { t } = useTranslation("global");

  const { slug } = useParams();
  const navigate = useNavigate();

  const adjustFontSize = (increment) => {
    setFontSize((prev) => {
      const newNumber = Math.min(5, Math.max(1, prev.number + increment));

      return {
        ...prev,
        number: newNumber,
        title: [
          "max-md:text-xl text-3xl",
          "max-md:text-2xl text-4xl",
          "max-md:text-3xl text-5xl",
          "max-md:text-4xl text-6xl",
          "max-md:text-5xl text-7xl",
        ][newNumber - 1], // Array'den direkt olarak font değerini alıyoruz
        desc: ["text-sm", "text-lg", "text-xl", "text-2xl", "text-3xl"][
          newNumber - 1
        ],
      };
    });
  };

  const handleSocialShare = (platform) => {
    const url = encodeURIComponent(window.location.href);
    const message = encodeURIComponent(blogData?.title);
    const platforms = {
      whatsapp: `https://wa.me/?text=${message}: ${url}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      twitter: `https://twitter.com/intent/tweet?url=${url}&text=${message}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    };
    window.open(platforms[platform], "_blank");
  };
  const navigateHandle = (url) => {
    window.scrollTo(0, 0);
    navigate(url);
  };

  const otherBlogs = t("blogItems", { returnObjects: true })
    ?.filter((blog) => blog.slug !== slug)
    .slice(-4)
    .reverse();

  const stickyBar = [
    [
      {
        icon: <MdOutlineTextIncrease size={32} />,
        func: () => adjustFontSize(+1),
      },
      {
        icon: <MdOutlineTextDecrease size={32} />,
        func: () => adjustFontSize(-1),
      },
      {
        icon: <RiFileCopyLine size={32} />,
      },
    ],

    [
      {
        icon: <FaWhatsapp size={28} />,
        func: () => handleSocialShare("whatsapp"),
      },
      {
        icon: <FaFacebookF size={28} />,
        func: () => handleSocialShare("facebook"),
      },
      {
        icon: <FaXTwitter size={28} />,
        func: () => handleSocialShare("twitter"),
      },
      {
        icon: <FaLinkedinIn size={28} />,
        func: () => handleSocialShare("linkedin"),
      },
    ],
  ];

  useEffect(() => {
    const selectedBlog = t("blogItems", { returnObjects: true })?.find(
      (blog) => blog.slug === slug
    );

    if (selectedBlog) {
      setBlogData(selectedBlog);
      setBreadcrumbPath([
        { name: "Anasayfa", url: "/" },
        { name: "Blog & Haber", url: "/blog" },
        { name: selectedBlog.title, url: "/" },
      ]);
    }
  }, [slug, t]);

  return (
    <>
      <div className="h-full w-full select-none">
        <div className="h-full max-md:overflow-hidden relative max-md:px-8  mb-32">
          {/* <div className="max-sm:h-[650px] h-[450px] absolute w-full -z-20 overflow-hidden">
            <img
              src="/images/blog-bg.png"
              alt=""
              className="w-full h-full scale-110"
            />
          </div> */}

          <div className=" pt-48 flex justify-center items-center flex-col relative ">
            <div className="flex max-md:flex-col gap-10">
              {/* sticky-bar */}
              <div className="md:sticky max-md:hidden w-10 h-full top-5 max-lg:left-4 z-20 ">
                <div className="flex md:flex-col justify-start items-center gap-20 w-full">
                  <div className="flex flex-col gap-2">
                    {stickyBar[0]?.map((item, i) => (
                      <div
                        className={`cursor-pointer border-b border-[#e8e8e81a] pb-2`}
                        key={i}
                        onClick={item?.func}
                      >
                        {item?.icon}
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col gap-2">
                    {stickyBar[1]?.map((item, i) => (
                      <div
                        className={`cursor-pointer border-b border-[#e8e8e81a] pb-2`}
                        key={i}
                        onClick={item?.func}
                      >
                        {item?.icon}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* background-shadow */}
              <div className="absolute top-32 left-0 w-full h-full -z-10">
                <img
                  src="/images/bg-shadow2.webp"
                  alt=""
                  className="w-full h-screen"
                />
              </div>

              {/* blog-content */}
              <div className="max-w-[800px]">
                <Breadcrumb paths={breadcrumbPath} />
                <div className="flex flex-col gap-20">
                  {/* title */}
                  <h1 className="max-md:text-4xl text-7xl">
                    {blogData?.title}
                  </h1>
                  <div className="flex max-md:flex-wrap gap-2 max-md:justify-center text-white">
                    <div className="bg-[#262441] max-md:flex-grow-0 max-md:basis-[140px] text-center py-2 px-6 rounded-full max-md:text-sm max-md:bg-opacity-60 md:bg-opacity-80">
                      {blogData?.createdAt}
                    </div>
                    <div className="bg-[#262441] max-md:flex-grow-0 max-md:basis-[140px] text-center py-2 px-6 rounded-full max-md:text-sm max-md:bg-opacity-60 md:bg-opacity-80">
                      3 dk. okuma
                    </div>
                    <div className="bg-[#262441] max-md:flex-grow-0 max-md:basis-[190px] text-center py-2 px-6 rounded-full max-md:text-sm max-md:bg-opacity-60 md:bg-opacity-80">
                      {blogData?.views}
                    </div>
                  </div>
                </div>
                {/* sticky-bar-mobile */}
                <div className="w-full h-full top-0 left-0 mt-10 md:hidden z-30 ">
                  <div className="flex md:flex-col justify-between w-full items-center gap-4">
                    <div className="flex gap-2">
                      {stickyBar[0]?.map((item, i) => (
                        <div
                          className={`cursor-pointer border-r border-[#e8e8e81a] pe-2`}
                          key={i}
                          onClick={item?.func}
                        >
                          {item?.icon}
                        </div>
                      ))}
                    </div>
                    <div className="flex gap-2 ">
                      {stickyBar[1]?.map((item, i) => (
                        <div
                          className={`cursor-pointer border-r border-[#e8e8e81a] pe-2`}
                          key={i}
                          onClick={item?.func}
                        >
                          {item?.icon}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {blogData?.sections?.map((section) => (
                  <div className="mt-10">
                    {section?.title?.trim() !== "" && (
                      <h3 className={`${fontSize?.title}`}>{section?.title}</h3>
                    )}
                    {section?.content?.trim() !== "" && (
                      <p
                        className={`${fontSize?.desc} font-light mt-3`}
                        dangerouslySetInnerHTML={{ __html: section?.content }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Last-blogs */}
        <div className="max-md:px-8 min-h-[60vh] md:px-10 lg:px-20 xl:px-32 mb-10 relative w-full h-full overflow-hidden">
          <div className="pt-10 max-sm:pb-10">
            <h1 className="font-bold text-2xl sm:text-3xl md:text-4xl mb-10">
              Son Eklenen Bloglar
            </h1>
            <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
              {otherBlogs?.map((blog) => (
                <div
                  className="cursor-pointer"
                  onClick={() => navigateHandle(`/blog/${blog.slug}`)}
                >
                  <img
                    src={blog.image}
                    alt="Blog images"
                    className="h-64 w-full mb-5 object-cover"
                  />
                  <div className="px-2">
                    <h1 className="text-xl font-bold mb-3">{blog.title}</h1>
                    <p className="blog-card-desc line-clamp-3">
                      {blog?.subtitle}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default BlogDetails;
