import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import CompanySection from "../components/layouts/CompanySection";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollText from "../components/layouts/ScrollText";
import StickySections from "../components/layouts/CardList";
import Blog from "../components/layouts/Blog";
import Loading from "../components/layouts/Loading";
import { useTranslation } from "react-i18next";
import LogoSection from "../components/layouts/LogoSection";
import Cookie from "../components/layouts/Cookie";
import MainScreen from "../components/layouts/MainScreen";
import Slider from "../components/layouts/MouseSlider";
import CounterSection from "../components/layouts/CounterSection";
import Footer from "../components/layouts/Footer";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const [activeLogo, setActiveLogo] = useState(0);
  const { t } = useTranslation("global");

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveLogo((prev) => (prev >= 4 ? 0 : prev + 1));
    }, 3000);

    const leftDeviceImg = gsap.to(".device-text-area-left-device", {
      left: "10%",
      scrollTrigger: {
        trigger: ".device-text-area",
        start: "top 30%",
        end: "bottom 50%",
        scrub: true,
      },
    });
    const rightDeviceImg = gsap.to(".device-text-area-right-device", {
      left: "10%",
      scrollTrigger: {
        trigger: ".device-text-area",
        start: "top 30%",
        end: "bottom 30%",
        scrub: true,
      },
    });

    ["world", "device-text-area"]?.map((classN, i) => {
      gsap.set(`.${classN}`, {
        opacity: 1,
      });

      gsap.to(`.${classN}`, {
        opacity: 0.4,
        scrollTrigger: {
          trigger: `.${classN}`,
          start: "top -30%",
          end: "bottom 10%",
          scrub: true,
          toggleActions: "restart pause resume reverse",
        },
      });
    });

    return () => {
      clearInterval(interval);
      rightDeviceImg.kill();
      leftDeviceImg.kill();
    };
  }, []);

  return (
    <>
      <div className="relative select-none">
        <Loading />
        <Cookie />
        <MainScreen />
        <ScrollText />
        
        <Slider />

        {/* Şirketler */}
        <CompanySection />

        <LogoSection />

        {/* World */}
        <CounterSection />

        {/* Başarı */}
        <div className="device-text-area transition-all duration-300 overflow-hidden relative min-h-[80vh] h-full w-full flex items-center justify-center bg-[#F0F1FA] max-xl:px-10 xl:px-32">
          {/* device-left */}
          <div className="relative -scale-x-150 scale-150 max-lg:hidden">
            <img src="/images/device-right.png" alt="" />
            <div
              className="absolute device-text-area-left-device top-[0%] left-[10%] w-[80%] h-[100%] overflow-hidden"
              style={{
                transform:
                  "perspective(1280px) rotateX(10deg) rotateY(120deg) rotateZ(2deg)",
              }}
            >
              <img
                src="images/taff-bg.png"
                alt=""
                style={{ boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.3)" }}
                className="w-full h-[50%]"
              />
            </div>
          </div>
          {/* text */}
          <div className="relative z-10 flex flex-col justify-center items-center lg:pe-32">
            <div className="lg:ps-32 text-center flex flex-col">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl w-full">
                {t("homepage.basari.title")}
              </h1>
              <h4 className="my-5 max-sm:text-lg text-3xl font-light">
                {t("homepage.basari.subtitle")}
              </h4>
              <p className="max-sm:text-lg text-2xl font-light">
                {t("homepage.basari.desc")}
              </p>
            </div>
          </div>
          {/* device-right */}
          <div className="relative scale-150 max-lg:hidden">
            <img src="/images/device-right.png" alt="" />
            <div
              className="absolute device-text-area-right-device top-[0%] left-[10%] w-[80%] h-[100%] overflow-hidden"
              style={{
                transform:
                  "perspective(1280px) rotateX(10deg) rotateY(120deg) rotateZ(2deg)",
              }}
            >
              <img
                src="images/mendels-bg.png"
                alt=""
                className="w-full h-[50%]"
                style={{ boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.3)" }}
              />
            </div>
          </div>
        </div>

        {/* BLOGS */}
        <Blog />
      </div>

      <Footer />
    </>
  );
};

export default Home;
