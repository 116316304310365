import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/layouts/Breadcrumb";
import MetaData from "../components/layouts/MetaData";
import { useTranslation } from "react-i18next";
import Footer from "../components/layouts/Footer";

const BlogsPage = () => {
  const navigate = useNavigate();
  const [lastFourBlog, setLastFourBlog] = useState([]);
  const [blogItems, setBlogItems] = useState();

  const navigateHandle = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation("global");
  const searchBlog = (keyword) => {
    if (keyword.trim()) {
      setBlogItems(
        t("blogItems", { returnObjects: true })?.filter((item) =>
          item?.title.toLowerCase().includes(keyword.toLowerCase())
        )
      );
    } else {
      setBlogItems(t("blogItems", { returnObjects: true }));
    }
  };

  useEffect(() => {
    setLastFourBlog(
      t("blogItems", { returnObjects: true })?.slice(-4)?.reverse()
    );
    setBlogItems(t("blogItems", { returnObjects: true }));
  }, []);

  return (
    <>
      <div className="pt-48 relative">
        <MetaData title="Bloglar" />
        <div className="text-center relative w-full h-full">
          <h1 className="max-sm:text-4xl text-6xl text-white">
            {t("blogPage.title")}
          </h1>
          <div className="mt-5">
            <Breadcrumb
              paths={t("blogPage.breadcrumb", { returnObjects: true })}
            />
          </div>
          <div className="absolute max-sm:-top-96 sm:-top-[15rem] md:-top-[20rem] lg:-top-[25rem] xl:-top-[30rem] left-0 h-full w-full -z-10">
            <img
              src="/images/bg-shadow.webp"
              alt=""
              className="w-full h-screen"
            />
          </div>
        </div>

        <div className="flex max-lg:flex-col xl:px-32 sm:px-16 max-sm:px-5 gap-6 py-20">
          <div className="lg:w-3/4">
            <div className="mb-4 lg:hidden">
              <h1 className="text-2xl font-bold text-white mb-3">
                {t("blogPage.search.text")}
              </h1>
              <input
                type="text"
                name="search"
                onChange={(e) => searchBlog(e.target.value)}
                placeholder={t("blogPage.search.placeholder")}
                className="border border-[#ffffff0d] bg-transparent focus:bg-white rounded-2xl h-12 w-full ps-4 py-8"
              />
            </div>
            <div className="flex flex-col gap-10">
              {blogItems?.map((item, index) => (
                <div
                  className="blog-card cursor-pointer hover:border max-sm:border border-[#ffffff0d] max-sm:pb-10 sm:pb-32 group rounded-3xl relative overflow-hidden transition-all"
                  onClick={() => navigateHandle(`/blog/${item?.slug}`)}
                >
                  <div className=" absolute max-sm:hidden bg-black w-32 h-32 -bottom-32 -right-32 group-hover:-bottom-20 group-hover:-right-20 transition-all duration-500 rounded-lg">
                    <div className="absolute top-3 left-3 w-full h-full">
                      <i class="ri-arrow-right-up-line text-white text-xl"></i>
                    </div>
                  </div>
                  <div className="absolute w-full h-full bg-gradient-to-t from-black to-transparent opacity-0 group-hover:opacity-10 transition-all duration-500" />
                  <img
                    src={item?.image}
                    loading={index >= 2 && "lazy"}
                    alt={`Blog image ${index}`}
                    className={`w-full max-sm:h-80 sm:h-96 mb-5 object-cover group-hover:rounded-3xl sm:px-5 group-hover:px-0 transition-all duration-500`}
                  />
                  <div className="max-sm:px-4 sm:px-10 relative">
                    <div className="">
                      <span className="text-[#0D0E10] bg-gray-300 py-2 px-4 rounded-full opacity-80">
                        {item?.createdAt}
                      </span>
                    </div>
                    <h1 className="max-sm:text-xl sm:text-2xl font-bold mt-5">
                      {item?.title}
                    </h1>
                    <p className="font-light">{item?.subtitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-1/4 sticky top-0 right-0 h-full">
            {/* SEARCH-AREA */}
            <div className="max-lg:hidden">
              <h1 className="text-2xl font-bold text-white mb-3">
                {t("blogPage.search.text")}
              </h1>
              <input
                type="text"
                name="search"
                onChange={(e) => searchBlog(e.target.value)}
                placeholder={t("blogPage.search.placeholder")}
                className="border border-[#bd14d3ec] bg-transparent focus:bg-black rounded-2xl h-12 w-full ps-4 py-8"
              />
            </div>
            {/* SERVICES-AREA */}
            {/* <div className="mt-10">
            <h1 className="text-2xl font-bold mb-5 text-white">SERVİSLERİMİZ</h1>
            <div className="flex flex-col gap-2 text-[#0D0E10]">
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
              <div className="flex justify-between cursor-pointer px-2 hover:bg-[#0E38B1] py-4 rounded-md hover:text-white transition-all">
                <a href="" className="">
                  SERVIS 1
                </a>
                <i class="ri-arrow-right-line"></i>
              </div>
            </div>
          </div> */}
            {/* SON EKLENEN BLOGLAR */}
            <div className="mt-10">
              <h1 className="text-2xl font-bold mb-5">
                {t("blogPage.blog.title")}
              </h1>
              <div className="flex flex-col gap-4">
                {lastFourBlog?.map((item) => (
                  <div
                    className="flex gap-2 cursor-pointer"
                    onClick={() => navigateHandle(`/blog/${item?.slug}`)}
                  >
                    <img
                      src={item?.image}
                      alt=""
                      className="w-28 h-28 object-cover rounded-md"
                    />
                    <div className="flex flex-col justify-between">
                      <span>{item?.createdAt}</span>
                      <h1 className="line-clamp-1 font-bold">{item?.title}</h1>
                      <h1 className="line-clamp-2">{item?.subtitle}</h1>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogsPage;
