import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollBar from "./ScrollBar";
gsap.registerPlugin(ScrollTrigger);

const ScrollText = () => {
  const textRef = useRef(null);

  // Farklı renkler oluşturuyoruz
  const colors = [
    "#ff0000",
    "#ff7f00",
    "#ffff00",
    "#00ff00",
    "#0000ff",
    "#4b0082",
    "#9400d3",
  ];

  useEffect(() => {
    const textElement = textRef.current;
    const text = textElement.innerText;

    // 'Generations' kelimesini bulup başlangıçta #bebfc0 ve kaydırma ile rengarenk yapmak
    const modifiedText = text
      .split(" ")
      .map((word) => {
        if (word.toLowerCase() === "generations") {
          // "Generations" kelimesini başlangıçta #bebfc0 yapmak ve sonra renkli yapmak
          return word
            .split("")
            .map(
              (char, index) =>
                `<span class="letter" data-index="${index}" style="color:#bebfc0">${char}</span>`
            )
            .join("");
        } else {
          // Diğer kelimeleri siyah renkte bırakıyoruz
          return `<span class="non-generation">${word}</span>`;
        }
      })
      .join(" ");
    textElement.innerHTML = modifiedText;

    // "Generations" harflerinin scroll ile rengarenk olmasını sağlıyoruz
    gsap.to(".letter", {
      color: () => colors[Math.floor(Math.random() * colors.length)], // Random renk
      duration: 0.5,
      stagger: 0.05,
      scrollTrigger: {
        trigger: textElement,
        start: "top 80%",
        end: "bottom top",
        scrub: true,
      },
    });

    // Diğer kelimeler için scroll ile siyah renk animasyonu
    gsap.to(".non-generation", {
      color: "#000", // Diğer kelimeler her zaman siyah kalacak
      duration: 0.5,
      scrollTrigger: {
        trigger: textElement,
        start: "top 80%",
        end: "bottom top",
        scrub: true,
      },
    });
  }, []);

  return (
    <div className="relative w-full max-sm:min-h-[100vh] sm:h-[80vh] main-content">
      {/* SVG Container */}
      <ScrollBar />

      {/* İçerik */}
      <div className="sticky z-20 top-0 left-0 flex items-center justify-center h-screen text-[#bebfc0]">
        <div
          className="scroll-text max-lg:text-4xl lg:text-6xl text-center"
          ref={textRef}
        >
          Technology for Next Generations
        </div>
        
      </div>
    </div>
  );
};

export default ScrollText;
