import React from 'react'
import {Helmet} from 'react-helmet';

const MetaData = ({title, description}) => {
  return (
    <Helmet >
       <title>{title} • Technology for Next Generations</title>
       <meta name="description" content={`${description} ellusion.tech`} /> 
    </Helmet>
  )
}

export default MetaData