import React from "react";
import {useNavigate} from 'react-router-dom';
const BlogItem = ({blog}) => {
    const navigate = useNavigate();
    const onClickHandle = (slug) => {
        navigate(`/blog/${slug}`)
        window.scrollTo(0,0)
    }
  return (
    <div className="card md:px-5 cursor-pointer" onClick={() => onClickHandle(blog?.slug)}>
      <div className="rounded-xl">
        <img
          src={blog?.image}
          loading="lazy"
          alt="Blog image"
          className="rounded-3xl max-sm:max-h-48 max-sm:h-48 sm:max-h-80 sm:h-full w-full object-cover"
        />
      </div>
      <div className="flex flex-wrap mt-5 gap-2 md:ps-5 w-full">
        <div className="flex h-11 items-center justify-center rounded-full bg-white/5 px-6 leading-none duration-350 group-hover/blog:bg-white/10 xl:h-10 xl:px-5 lg:h-9 lg:px-4.5 lg:text-3.5 md:px-4 max-sm:text-[12px] font-light">
          {blog?.createdAt}
        </div>
        <div className="flex h-11 items-center justify-center rounded-full bg-white/5 px-6 leading-none duration-350 group-hover/blog:bg-white/10 xl:h-10 xl:px-5 lg:h-9 lg:px-4.5 lg:text-3.5 md:px-4 max-sm:text-[12px] font-light">
        {blog?.views}
        </div>
      </div>
      <div className="max-sm:text-xl text-3xl mt-5 ps-5">
        <h1>{blog?.title}</h1>
      </div>
    </div>
  );
};

export default BlogItem;
