import { useEffect, useRef } from "react";
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const StickySections = () => {
  const sectionsRef = useRef([]);
  const { t } = useTranslation("global");
  const sections = t("homepage.stickySections", { returnObjects: true });
 

  return (
    <div className="wrap w-full mb-20 bg-[#F0F1FA]">
      {sections.map((section, index) => (
        <div
          key={index}
          ref={(el) => (sectionsRef.current[index] = el)}
          className={`content content--sticky z-10 pt-10 content--grid w-full max-xl:px-8 xl:px-32 rounded-xl`}
          style={{ "--offset": `${index * 2}rem` }}
        >
          <div className="h-[80vh] w-full rounded-xl relative z-20">
            <div className="absolute top-3 right-3 bg-transparent h-10 w-10 z-20">
              <div className="flex justify-center items-center py-1 rounded-full gap-1 bg-white bg-opacity-25">
                <div className="w-2 h-2 rounded-full bg-black opacity-50" />
                <div className="w-2 h-2 rounded-full bg-black opacity-75" />
                <div className="w-2 h-2 rounded-full bg-black opacity-100" />
              </div>
            </div>
            {/* Arka Plan Görseli */}
            <div className="absolute top-0 left-0 object-contain w-full h-full z-10">
              <img
                src={section?.img}
                loading="lazy"
                alt="Card image"
                className="rounded-xl w-full h-full"
              />
            </div>

            {/* İçerik Alanı */}
            <div
              className={`relative flex flex-col md:flex-row justify-center items-center w-full h-full z-40 px-4 md:px-10 gap-4 md:gap-10 ${
                index % 2 !== 0 ? "" : "md:flex-row-reverse"
              }`}
            >
              {/* Cihaz Görseli (Mobilde Gizli) */}
              <div className="hidden md:block w-3/7">
                <img
                  src="/images/device-bg.png"
                  alt=""
                  className={`${index % 2 !== 0 && "card-device-img-left"}`}
                />
              </div>

              {/* Metin Alanı */}
              <div
                className={`card-context pb-2 w-full text-center md:text-left ${
                  index === 2 ? "text-blue-700" : "text-white"
                }`}
              >
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold">
                  {section?.title}
                </h1>
                <div
                  className={`w-full h-[1px] my-3 md:my-4 ${
                    index === 2 ? "bg-blue-700" : "bg-[#ffffff45]"
                  }`}
                />
                <h4 className="text-lg sm:text-xl md:text-2xl lg:text-4xl font-extralight opacity-80">
                  {section?.subtitle}
                </h4>
                <div
                  className={`w-full h-[1px] my-3 md:my-4 ${
                    index === 2 ? "bg-blue-700" : "bg-[#ffffff45]"
                  }`}
                />

                {/* Açıklama */}
                <div className="flex flex-col md:flex-row justify-center md:justify-start gap-2 max-sm:text-sm">
                  <h2>{section?.desc}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StickySections;
