import React from "react";

const Input = ({
  type,
  name,
  value,
  classname,
  placeholder,
  onChange,
  onClick,
  required,
}) => {
  return (
    <input
      type={type}
      name={name}
      value={value}
      required
      className={`${classname}`}
      placeholder={`${placeholder}`}
      onChange={onChange}
      onClick={onClick}
    />
  );
};

export default Input;
