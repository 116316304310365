import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import useClickOutside from "../../hooks/useClickOutside";
import { useNavigate } from "react-router-dom";
import MagnetButton from "./MagnetButton";
const Header = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();

  const [showSidebar, setShowSidebar] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);
  const [showDropdown, setShowDropdown] = useState({
    show: false,
    number: null,
    items: [],
  });

  const languageRef = useClickOutside(() => setShowLanguage(false));
  const [language, setLanguage] = useState(
    localStorage.getItem("lng") ? localStorage.getItem("lng") : "tr"
  );

  const headerItems = t("header", { returnObjects: true });
  const socialMediaItems = [
    {
      url: "https://www.instagram.com/ellusiontech/",
      icon: "ri-instagram-line",
    },
    {
      url: "https://www.linkedin.com/",
      icon: "ri-linkedin-box-fill",
    },
    {
      url: "https://www.youtube.com/",
      icon: "ri-youtube-fill",
    },
    {
      url: "https://www.x.com/",
      icon: "ri-twitter-x-line",
    },
  ];

  const changeLanguageHandler = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lng", language);
    setLanguage(language);
  };

  useEffect(() => {
    i18n.changeLanguage(language);

    if (showSidebar) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; // Sayfa hareketini tamamen durdur
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.position = "static"; // Sayfayı eski haline getir
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup işlemi
      document.body.style.position = "static";
    };
  }, [showSidebar, language, i18n]);

  return (
    <header
      ref={languageRef}
      className={`relative bg-transparent ${
        showDropdown?.show ? "z-20" : "z-50"
      }`}
    >
      {/* Blur Arka Plan */}
      {showSidebar && (
        <div className="fixed w-full h-screen top-0 left-0 backdrop-blur-sm bg-black/50 z-30"></div>
      )}

      {/* MENU */}
      <nav
        className={`fixed top-0 left-0  w-full bg-[#090B10] transition-all duration-700 transform  
        ${
          showSidebar
            ? "max-sm:h-[calc(100vh-35%)] h-[calc(100vh-25%)] opacity-100 z-50"
            : "h-0 opacity-0 -z-50"
        }`}
      >
        {/* social media items */}
        <div className="flex flex-col items-center justify-center max-sm:gap-5 max-sm:-mb-16">
          <div
            className={` gap-2 items-center justify-center mt-2 ${
              showSidebar ? "flex" : "hidden"
            }`}
          >
            {socialMediaItems?.map((item, i) => (
              <div className="" key={i}>
                <a href={item?.url} target="_blank">
                  <i
                    className={`${item?.icon} text-[#ffffffa6] text-3xl hover:text-white transition-all duration-300`}
                  />
                </a>
              </div>
            ))}
          </div>
          <div className="">
            <Button
              text={"Teklif Al"}
              className={"max-sm:h-12 h-16 w-full sm:hidden"}
              style={2}
              color={"white"}
              onClick={() => navigate("/teklif-al")}
            />
          </div>
        </div>

        {/* menu-items */}
        <div
          className={` flex-col justify-center items-center w-full h-full gap-5 transition-all duration-300 ${
            showSidebar ? "flex" : "hidden"
          } ${showDropdown?.show ? "opacity-0 hidden" : "opacity-100 flex"}`}
        >
          {headerItems.map((item, index) => (
            <a
              key={index}
              href={item?.url}
              className="text-[#848588] max-sm:text-4xl text-6xl cursor-pointer hover:text-white transition-all duration-300"
              onClick={() =>
                item?.dropdown &&
                setShowDropdown({
                  show: true,
                  number: item?.dropdownNumber,
                  items: item?.dropdownItems,
                })
              }
            >
              {item?.text}
              {item?.dropdown && <i class="ri-arrow-right-s-line"></i>}
            </a>
          ))}
        </div>

        {/* dropdown */}
        <div
          className={`flex-col justify-center items-center w-full h-full gap-5 transition-all duration-300 z-50 ${
            showDropdown?.show ? "opacity-100 flex" : "opacity-0 hidden"
          }`}
        >
          {showDropdown?.items.map((item, index) => (
            <a
              key={index}
              href={`/hizmetler${item?.url}`}
              className="text-[#848588] max-sm:text-4xl text-6xl cursor-pointer hover:text-white transition-all duration-300 text-center"
              onClick={() =>
                item?.dropdown &&
                setShowDropdown({ show: true, number: item?.dropdownNumber })
              }
            >
              {item?.name}
            </a>
          ))}
          <button
            className="text-[#848588] text-3xl cursor-pointer hover:text-white transition-all duration-300"
            onClick={() =>
              setShowDropdown({ show: false, number: null, items: [] })
            }
          >
            <i class="ri-arrow-left-line"></i> Geri
          </button>
        </div>

        {/* Sidebar Kapat Butonu */}
        {showSidebar && (
          <div className="flex justify-center mt-5">
            <MagnetButton>
              <div
                className="max-sm:h-16 max-sm:w-16 h-20 w-20 rounded-full bg-[#090B10] flex justify-center items-center cursor-pointer"
                onClick={() => {
                  setShowSidebar(false);
                  setShowDropdown({ show: false, number: null, items: [] });
                }}
              >
                <IoCloseSharp color="white" size={32} />
              </div>
            </MagnetButton>
          </div>
        )}
      </nav>

      {/* Navbar */}
      <nav className="absolute z-30 top-0 left-0 w-full flex justify-between items-center lg:px-10 lg:ps-32 mt-4 pe-8">
        {/* logo */}
        <div className="p-2 rounded">
          <a href="/">
            <img
              src="/images/logo-black.png"
              loading="eager"
              alt="Ellusion Logo"
              className="max-md:w-48 max-md:h-14 md:w-64 md:h-20 object-cover"
            />
          </a>
        </div>

        {/* buttons */}
        <div className="flex items-center gap-4 inter-medium">
          <Button
            text={"Teklif Al"}
            className={"max-sm:h-12 h-16 px-6 max-sm:hidden"}
            style={3}
            color={"white"}
            onClick={() => navigate("/teklif-al")}
          />

          {/* language button */}
          <div
            className="select-none flex flex-col items-center justify-center rounded-full bg-black max-sm:h-12 max-sm:w-12 h-16 w-16 gap-2 px-3 cursor-pointer relative"
            onClick={() => setShowLanguage(!showLanguage)}
          >
            <i class="ri-translate-2 text-white" />
            <div
              className={`flex flex-col gap-2 justify-center ps-2 absolute max-md:top-14 md:top-20 right-0 bg-white w-32 rounded-xl shadow-md transition-all duration-300 ${
                showLanguage ? "h-24" : "h-0"}`}>
              <div className={`${showLanguage ? "flex" : "hidden"} items-center gap-2`}onClick={() => changeLanguageHandler("tr")} >
                <img
                  src="/images/flags/turkey.png"
                  className={`w-8 transition-all duration-500 ${
                    showLanguage ? "h-8" : "h-0"
                  }`}
                />
                <span className="">Türkçe</span>
              </div>
              <div
                className={`${
                  showLanguage ? "flex opacity-100" : "hidden opacity-0"
                } items-center gap-2`}
                onClick={() => changeLanguageHandler("en")}
              >
                <img
                  src="/images/flags/united-states.png"
                  className={`w-8 transition-all duration-500 ${
                    showLanguage ? "h-8" : "h-0"
                  }`}
                />
                <span className="">English</span>
              </div>
            </div>
          </div>
          {/* navbar-button */}
          <div
            className="flex flex-col items-center justify-center bg-black max-sm:h-12 max-sm:w-12 h-16 w-16 rounded-full gap-2 px-3 cursor-pointer"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <span className="w-full bg-white h-[1px]" />
            <span className="w-full bg-white h-[1px]" />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
