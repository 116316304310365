import React from "react";
import Typewriter from "./TypeWriter";
import { useTranslation } from "react-i18next";

const OldMainScreen = () => {
  const { t } = useTranslation("global");
  return (
    <div className="xl:hidden main-screen relative flex max-md:flex-col md:justify-between md:items-center sm:h-screen h md:h-1/2 xl:h-screen items-center md:px-10 2xl:pe-32 2xl:ps-48 w-full max-xl:pt-32 max-md:px-8">
      {/* bg-img */}
      <div className="absolute top-0 right-0 inset-0">
        <img
          src="/images/home4.jpg"
          alt="Homepage background"
          className="w-full h-full z-10"
        />
      </div>

      {/* TEXT */}
      <Typewriter />

      {/* DEVICE */}
      <div className="flex justify-center items-center max-md:-mt-16 max-md:mb-28">
        <div className="relative max-md:w-full md:w-[300px] xl:w-[500px]">
          {/* Laptop Arka Planı */}
          <img
            src="/images/device-bg.png"
            alt="Laptop"
            className="w-full h-full"
          />

          {/* Ekran İçeriği */}
          <div
            className="absolute top-[12%] left-[32%] w-[61%] h-[52%] overflow-hidden"
            style={{
              transform:
                "perspective(5000px) rotateX(15deg) rotateY(-8deg) rotateZ(21deg)",
            }}
          >
            <video
              className="w-full h-full object-cover rounded-lg"
              autoPlay
              loop
              muted
            >
              <source src="/video/device_video.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      {/* MOUSE */}
      <div className="absolute bottom-10 left-0 flex justify-center items-center w-full gap-2 group z-20 cursor-pointer">
        <div className="h-8 w-4 border border-[#000] group-hover:border-gray-700 transition-all duration-300 rounded-full relative">
          <div className="absolute top-0 left-0 w-full flex justify-center items-center">
            <span className="mouse transition-all duration-300 h-2 bg-[#000] group-hover:bg-gray-700 w-[2px] rounded-full" />
          </div>
        </div>
        <p className="text-[#000]transition-all duration-300">
          {t("homepage.mouseText")}
        </p>
      </div>
    </div>
  );
};

export default OldMainScreen;
