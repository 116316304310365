import { useEffect, useRef } from "react";
import Matter from "matter-js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const BallPool = () => {
  const sceneRef = useRef(null);
  const prevMouse = useRef({ x: 0, y: 0 });

  // SVG path'lerinden oluşturulacak yığınlar için ortak fonksiyon
  const createSVGStack = (svgPath) => {
    return Matter.Composites.stack(0, 0, 30, 10, 20, 20, (x, y) => {
      let vertices = Matter.Vertices.fromPath(svgPath);
      Matter.Vertices.scale(vertices, 0.5, 0.5);
      return Matter.Bodies.fromVertices(x, y, vertices, {
        restitution: 0.8,
        friction: 0.05,
        render: {
          sprite: {
            texture:
              "data:image/svg+xml," +
              encodeURIComponent(
                `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 50">
                  <path fill="black" d="${svgPath}"/>
                </svg>`
              ),
            xScale: 1,
            yScale: 1,
          },
        },
      });
    });
  };

  // Kullanılacak SVG path'ler
  const instaSvgPath =
    "M 16 3 C 8.8545455 3 3 8.8545455 3 16 L 3 34 C 3 41.145455 8.8545455 47 16 47 L 34 47 C 41.145455 47 47 41.145455 47 34 L 47 16 C 47 8.8545455 41.145455 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.054545 5 45 9.9454545 45 16 L 45 34 C 45 40.054545 40.054545 45 34 45 L 16 45 C 9.9454545 45 5 40.054545 5 34 L 5 16 C 5 9.9454545 9.9454545 5 16 5 z M 37 11 C 35.9 11 35 11.9 35 13 C 35 14.1 35.9 15 37 15 C 38.1 15 39 14.1 39 13 C 39 11.9 38.1 11 37 11 z M 25 14 C 18.954545 14 14 18.954545 14 25 C 14 31.045455 18.954545 36 25 36 C 31.045455 36 36 31.045455 36 25 C 36 18.954545 31.045455 14 25 14 z M 25 16 C 29.954545 16 34 20.045455 34 25 C 34 29.954545 29.954545 34 25 34 C 20.045455 34 16 29.954545 16 25 C 16 20.045455 20.045455 16 25 16 z";
  const youtubeSvgPath =
    "M 24.402344 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.402344 16.898438 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.902344 40.5 17.898438 41 24.5 41 C 31.101563 41 37.097656 40.5 40.597656 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.097656 35.5 C 45.5 33 46 29.402344 46.097656 24.902344 C 46.097656 20.402344 45.597656 16.800781 45.097656 14.300781 C 44.699219 12.101563 42.800781 10.5 40.597656 10 C 37.097656 9.5 31 9 24.402344 9 Z M 24.402344 11 C 31.601563 11 37.398438 11.597656 40.199219 12.097656 C 41.699219 12.5 42.898438 13.5 43.097656 14.800781 C 43.699219 18 44.097656 21.402344 44.097656 24.902344 C 44 29.199219 43.5 32.699219 43.097656 35.199219 C 42.800781 37.097656 40.800781 37.699219 40.199219 37.902344 C 36.597656 38.601563 30.597656 39.097656 24.597656 39.097656 C 18.597656 39.097656 12.5 38.699219 9 37.902344 C 7.5 37.5 6.300781 36.5 6.101563 35.199219 C 5.300781 32.398438 5 28.699219 5 25 C 5 20.398438 5.402344 17 5.800781 14.902344 C 6.101563 13 8.199219 12.398438 8.699219 12.199219 C 12 11.5 18.101563 11 24.402344 11 Z M 19 17 L 19 33 L 33 25 Z M 21 20.402344 L 29 25 L 21 29.597656 Z";
  const xSvgPath =
    "M 11 4 C 7.1456661 4 4 7.1456661 4 11 L 4 39 C 4 42.854334 7.1456661 46 11 46 L 39 46 C 42.854334 46 46 42.854334 46 39 L 46 11 C 46 7.1456661 42.854334 4 39 4 L 11 4 z M 11 6 L 39 6 C 41.773666 6 44 8.2263339 44 11 L 44 39 C 44 41.773666 41.773666 44 39 44 L 11 44 C 8.2263339 44 6 41.773666 6 39 L 6 11 C 6 8.2263339 8.2263339 6 11 6 z M 13.085938 13 L 22.308594 26.103516 L 13 37 L 15.5 37 L 23.4375 27.707031 L 29.976562 37 L 37.914062 37 L 27.789062 22.613281 L 36 13 L 33.5 13 L 26.660156 21.009766 L 21.023438 13 L 13.085938 13 z M 16.914062 15 L 19.978516 15 L 34.085938 35 L 31.021484 35 L 16.914062 15 z";

  useEffect(() => {
    const {
      Engine,
      Render,
      Runner,
      Composite,
      Bodies,
      Vertices,
      Body,
      Vector,
    } = Matter;

    // Engine ve render ayarları
    const engine = Engine.create();
    const world = engine.world;
    const width = window.innerWidth;
    const height = window.innerHeight;

    const render = Render.create({
      element: sceneRef.current,
      engine: engine,
      options: {
        width,
        height,
        wireframes: false,
        background: "transparent",
      },
    });

    Render.run(render);
    const runner = Runner.create();
    Runner.run(runner, engine);

    // Sınır duvarları oluşturuluyor
    const wallOptions = {
      isStatic: true,
      render: { fillStyle: "transparent" },
    };
    const floor = Bodies.rectangle(width / 2, height, width, 50, wallOptions);
    const ceiling = Bodies.rectangle(width / 2, 0, width, 50, wallOptions);
    const leftWall = Bodies.rectangle(0, height / 2, 50, height, wallOptions);
    const rightWall = Bodies.rectangle(
      width,
      height / 2,
      50,
      height,
      wallOptions
    );
    Composite.add(world, [floor, ceiling, leftWall, rightWall]);

    // SVG yığınlarını oluştur ve dünyaya ekle
    const instaStack = createSVGStack(instaSvgPath);
    const youtubeStack = createSVGStack(youtubeSvgPath);
    const xStack = createSVGStack(xSvgPath);
    Composite.add(world, [instaStack, youtubeStack, xStack]);

    // Fare etkileşimi için görünmez bir daire oluşturuluyor
    const mouseRadius = 50;
    const mouseCircle = Bodies.circle(-100, -100, mouseRadius, {
      isStatic: true,
    });

    // Fare hareketinde kuvvet uygulama
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      Body.setPosition(mouseCircle, { x: clientX, y: clientY });

      // Önceki fare konumuna göre kuvvet hesaplanıyor
      const dx = clientX - prevMouse.current.x;
      const dy = clientY - prevMouse.current.y;
      const force = { x: dx * 0.001, y: dy * 0.001 };

      // Tüm yığınlardaki cisimlere kuvvet uygulanıyor
      const allBodies = [
        ...instaStack.bodies,
        ...youtubeStack.bodies,
        ...xStack.bodies,
      ];
      allBodies.forEach((body) => {
        const distance = Vector.magnitude(
          Vector.sub(body.position, { x: clientX, y: clientY })
        );
        if (distance < mouseRadius) {
          Body.applyForce(body, body.position, force);
        }
      });
      prevMouse.current = { x: clientX, y: clientY };
    };

    document.addEventListener("mousemove", handleMouseMove);

    // Pencere boyutu değiştiğinde canvas'ı güncelleme
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      render.canvas.width = newWidth;
      render.canvas.height = newHeight;
      Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: newWidth, y: newHeight },
      });
    };

    window.addEventListener("resize", handleResize);

    gsap.fromTo(
      ".underline-animation",
      {
        width: "0%",
      },
      {
        width: "100%",
        duration: 2,
        scrollTrigger: {
          trigger: ".ball-pool-wrapper",
          toggleActions: "play pause resume reset",
          start: "top 30%",
          end: "bottom 30%",
          scrub: true,
        },
      }
    );

    // Cleanup
    return () => {
      Render.stop(render);
      Runner.stop(runner);
      Composite.clear(world);
      Engine.clear(engine);
      document.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="h-[150vh] ball-pool-wrapper">
      <div
        ref={sceneRef}
        className="relative top-0 left-0 w-full h-full overflow-hidden"
      >
        {/* top text */}
        <div className="absolute top-0 left-0 w-full max-sm:px-8 px-32 py-10 text-white">
          <div className="flex justify-between items-center">
            <h1 className="max-sm:text-3xl text-5xl">ellusion</h1>
          </div>
        </div>
        {/* background image */}
        <div className="absolute top-0 left-0 w-full h-full">
          <img src="/images/bg-shadow4.webp" alt="" />
        </div>
        {/* mouse rounded */}
        <div className="absolute top-0 left-0 w-[100px] h-[100px] rounded-full pointer-events-none" />

        {/* centered text */}
        <div className="absolute top-0 left-0 w-full h-full z-10 flex justify-center items-center -mt-[28vh]">
          <div className="relative flex justify-center items-center flex-col gap-10 max-lg:p-10 lg:p-24 text-white">
            <div className="absolute bottom-0 left-0 z-20">
              <span className="max-sm:text-2xl text-4xl text-white">+</span>
            </div>
            <div className="absolute top-0 right-0 z-20">
              <span className="max-sm:text-2xl text-4xl text-white">+</span>
            </div>
            <span>Lorem ipsum dolor sit amet.</span>
            <div className="w-full">
              <p className="max-sm:text-6xl text-8xl text-center">Let's work</p>
              <div className="underline-animation h-3 bg-white w-[0%]" />
            </div>
            <div className="w-full">
              <p className="max-sm:text-6xl text-8xl text-center">togheter!</p>
              <div className="underline-animation h-3 bg-white w-0 mt-3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BallPool;
