import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const mainApi = createApi({
  reducerPath: "mainApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api", credentials: "include" }),
  endpoints: (builder) => ({
    sendTrade: builder.mutation({
        query(body) {
         return {
            url: "/send-trade",
            method: "POST",
            body
         }
        }
    }),
  }),
});

export const {useSendTradeMutation} = mainApi
