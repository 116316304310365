import React from "react";
import { FaRegMap } from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import MetaData from "../components/layouts/MetaData";
import { useTranslation } from "react-i18next";
const ContactPage = () => {
  const { t } = useTranslation("global");

  return (
    <div className="min-h-screen h-full w-full relative">
      <MetaData title="İletişim" />
      {/* backgorund-images */}
      <div className="absolute h-full w-full overflow-hidden -z-10">
        <img
          src="/images/world.jpg"
          alt="bg-img"
          className="scale-150 z-10 h-full w-full max-md:hidden"
        />
        <img
          src="/images/bg-shadow2.webp"
          alt="bg-img"
          className="scale-150 z-10 h-1/2 w-full md:hidden"
        />
      </div>

      <div className="flex max-xl:flex-col z-10 pt-48 max-lg:px-10 lg:px-32 max-md:gap-10 gap-32 h-full">
        <div className="flex-1">
          {/* text */}
          <h1
            className="max-md:text-4xl text-6xl"
            dangerouslySetInnerHTML={{ __html: t("contactPage.title") }}
          />
          <p className="max-md:text-xl text-2xl font-light mt-5">
            {t("contactPage.desc")}
          </p>

          {/* contact details */}
          <div className="mt-10 flex flex-col gap-4 w-full">
            <div className="flex gap-4 items-center max-md:text-xl text-4xl">
              <TfiHeadphoneAlt color="black" size={32} />
              <a href="tel:+90 0533 766 20-90">+90 0533 766 20-90</a>
            </div>
            <div className="bg-[#ffffff80] h-[1px] max-md:w-full md:w-4/6 "></div>
            <div className="flex gap-4 items-center max-md:text-xl text-4xl">
              <LuMail color="black" size={32} />
              <a href="mailto:contact@ellusion.tech">contact@ellusion.tech</a>
            </div>
          </div>
        </div>
        {/* maps */}
        <div className="flex-1 w-full h-full mb-10">
          <div className="flex flex-col justify-center items-center">
            <div className="w-full">
              <iframe
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Be%C5%9Fyol,%20%C4%B0n%C3%B6n%C3%BC%20Cd.%20No:38,%2034295,%2034000%20K%C3%BC%C3%A7%C3%BCk%C3%A7ekmece/%C4%B0stanbul+(My%20Business%20Name)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                height="450"
                style={{ border: 0, borderRadius: "50px", width: "100%" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="flex items-center justify-center text-2xl gap-4 mt-4">
              <FaRegMap color="black" size={32} />
              <p className="max-md:text-sm">
                Beşyol, İnönü Cd. No:38, 34295, 34000 Küçükçekmece/İstanbul
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
