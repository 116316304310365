import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Button from "../components/layouts/Button";
import Input from "../components/layouts/Input";
import { useTranslation } from "react-i18next";
import { useSendTradeMutation } from "../redux/api/mainApi";
import { toast } from "react-toastify";

const OfferPage = () => {
  const [step, setStep] = useState(1);
  const [kvkk, setKvkk] = useState(false);
  const [data, setData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    website: "",
  });
  const [sendTrade, { error, isSuccess }] = useSendTradeMutation();

  const [workData, setWorkData] = useState({
    webYazilim: false,
    mobil: false,
    logo: false,
    kimlik: false,
    video: false,
    sosyalMedyaYönetimi: false,
    sosyalMedyaReklami: false,
  });
  const {
    webYazilim,
    mobil,
    logo,
    kimlik,
    video,
    sosyalMedyaReklami,
    sosyalMedyaYönetimi,
  } = workData;

  const { firstname, lastname, email, phoneNumber, companyName, website } =
    data;
  const { t } = useTranslation("global");

  const increaseStep = (count) => {
    if (count) {
      setStep(count);
    } else {
      setStep(step + 1);
    }
  };
  const decreaseStep = (count) => {
    if (count) {
      setStep(count);
    } else {
      if (step >= 2) {
        setStep(step - 1);
      }
    }
  };
  const onChangeHandle = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const nextButtonHandler = () => {
    if (Object.values(workData).every((item) => item === false)) {
      toast.warning("Lütfen en az bir iş seçiniz");
    } else {
      setStep(3);
    }
  };

  const stepVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
  };
  const inputItems = [
    {
      type: "text",
      name: "firstname",
      value: firstname,
      placeholder: "Adınız (*)",
      onChange: onChangeHandle,
    },
    {
      type: "text",
      name: "lastname",
      value: lastname,
      placeholder: "Soyadınız (*)",
      onChange: onChangeHandle,
    },
    {
      type: "email",
      name: "email",
      value: email,
      placeholder: "E-Posta (*)",
      onChange: onChangeHandle,
    },
    {
      type: "tel",
      name: "phoneNumber",
      value: phoneNumber,
      placeholder: "Telefon Numarası (*)",
      onChange: onChangeHandle,
    },
    {
      type: "text",
      name: "companyName",
      value: companyName,
      placeholder: "Şirket Adı",
      onChange: onChangeHandle,
    },
    {
      type: "text",
      name: "website",
      value: website,
      placeholder: "Web Site Adresi",
      onChange: onChangeHandle,
    },
  ];

  const StepOne = () => {
    return (
      <>
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl text-center mb-16 text-white">Her Görüntü, Bir Hikaye Anlatır</h1>
        <div className="flex justify-center items-center w-full h-full">
          <div className="relative max-sm:w-full lg:w-1/2 max-md:h-full mdsm:h-96 bg-white/5 border border-white/20 rounded-3xl flex justify-between items-center flex-col max-sm:mx-5 sm:px-5 sm:py-10 gap-10">
            <div className="text-white text-center mt-5">
            <h1>Online Gücünüzü Artırın, Dijital Geleceğinizi Şekillendirin.</h1>
            </div>

            <div className="grid max-sm:grid-cols-1 grid-cols-2 max-lg:px-10 lg:px-20 max-lg:gap-10 lg:gap-20 mb-10 w-full h-full">
              <div
                className="bg-white/10 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full flex justify-center items-center flex-col cursor-pointer p-5"
                onClick={() => increaseStep(3)}
              >
                <i class="ri-message-2-line text-8xl"></i>
                <h1 className="text-xl">Hızlı Teklif Al</h1>
              </div>
              <div
                className="bg-white/10 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full  flex justify-center items-center flex-col cursor-pointer p-5"
                onClick={() => increaseStep(2)}
              >
                <i class="ri-shake-hands-line text-8xl"></i>
                <h1 className="text-xl">Bir Projem Var</h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const StepTwo = () => {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="relative max-sm:w-full lg:w-1/2 max-md:h-full md:h-96 bg-white/5 border border-white/20 rounded-3xl flex justify-between items-center flex-col max-sm:mx-5 sm:mx-5 sm:py-10 gap-10">
          <div
            className="absolute top-0 left-0 flex justify-center w-full"
            onClick={() => decreaseStep(1)}
          >
            <div className="w-16 h-16 rounded-full border bg-white bg-opacity-10 -mt-8 flex justify-center items-center cursor-pointer">
              <i class="ri-arrow-left-line text-2xl text-white"></i>
            </div>
          </div>

          <div className="grid max-sm:grid-cols-1 grid-cols-2 max-lg:px-10 lg:px-20 max-lg:gap-10 lg:gap-20 mb-10 w-full h-full mt-10">
            <div
              className="bg-white/10 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full flex justify-center items-center flex-col gap-3 cursor-pointer p-5"
              onClick={() => increaseStep(5)}
            >
              <i class="ri-building-2-fill text-8xl"></i>
              <h1 className="text-xl">Şirketim İçin</h1>
              <p className="text-center font-light underline">Web Tasarımınız İçin Doğru Tercih.</p>
            </div>
            <div
              className="bg-white/10 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full flex justify-center items-center flex-col gap-3 cursor-pointer p-5"
              onClick={() => increaseStep(5)} >
              <i class="ri-account-pin-box-line text-8xl"></i>
              <h1 className="text-xl">Kişisel</h1>
              <p className="text-center font-light underline">Ürünlerinizi Dijital Dünyada Satmaya Hazır Mısınız?</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const StepFive = () => {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="relative w-full h-full max-lg:mx-5 lg:mx-20 bg-white/5 border border-white/20 rounded-3xl flex justify-between items-center flex-col max-sm:mx-5 sm:px-5 sm:py-10 gap-10">
          <div
            className="absolute top-0 left-0 flex justify-center w-full"
            onClick={() => decreaseStep(2)}
          >
            <div className="w-16 h-16 rounded-full border bg-white bg-opacity-10 -mt-8 flex justify-center items-center cursor-pointer">
              <i class="ri-arrow-left-line text-2xl text-white"></i>
            </div>
          </div>

          <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-lg:px-5 lg:px-20 max-lg:gap-10 lg:gap-20 mb-10 w-full h-full mt-10">
            {/* WEB SECTION */}
            <div className="bg-white/10 hover:bg-white group transition-all duration-300 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full flex justify-between items-center flex-col gap-3 cursor-pointer p-5">
              <div className="flex items-center justify-center gap-5 w-full h-full">
                <i class="ri-building-2-fill group-hover:text-black text-8xl text-[#ffffffa8]"></i>
                <h1 className="text-xl group-hover:text-black text-[#ffffffa8]">
                  Web Yazılım
                </h1>
              </div>
              <div className="flex flex-col gap-2 mt-5">
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="webYazilim"
                    classname={"sr-only"}
                    value={webYazilim}
                    onClick={() =>
                      setWorkData({ ...workData, webYazilim: !webYazilim })
                    }
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          webYazilim ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Web Yazılım
                  </span>
                </label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="mobil"
                    classname={"sr-only"}
                    value={mobil}
                    onClick={() => setWorkData({ ...workData, mobil: !mobil })}
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          mobil ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Mobil Uygulama
                  </span>
                </label>
              </div>
            </div>
            {/* DESIGN SECTION */}
            <div className="bg-white/10 hover:bg-white group transition-all duration-300 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full flex justify-between items-center flex-col gap-3 cursor-pointer p-5">
              <div className="flex items-center justify-center gap-5 w-full h-full">
                <i class="ri-building-2-fill group-hover:text-black text-8xl text-[#ffffffa8]"></i>
                <h1 className="text-xl group-hover:text-black text-[#ffffffa8]">
                  Grafik Tasarım
                </h1>
              </div>
              <div className="flex flex-col gap-2 mt-5">
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="logo"
                    classname={"sr-only"}
                    value={logo}
                    onClick={() => setWorkData({ ...workData, logo: !logo })}
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          logo ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Logo Tasarım
                  </span>
                </label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="kimlik"
                    classname={"sr-only"}
                    value={kimlik}
                    onClick={() =>
                      setWorkData({ ...workData, kimlik: !kimlik })
                    }
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          kimlik ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Kurumsal Kimlik
                  </span>
                </label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="video"
                    classname={"sr-only"}
                    value={video}
                    onClick={() => setWorkData({ ...workData, video: !video })}
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          video ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Video Prodüksiyon
                  </span>
                </label>
              </div>
            </div>
            {/* DIGITAL SECTION */}
            <div className="bg-white/10 hover:bg-white group transition-all duration-300 text-white border border-white/40 bg-opacity-20 rounded-3xl w-full h-full flex justify-between items-center flex-col gap-3 cursor-pointer p-5">
            <div className="flex items-center justify-center gap-5 w-full h-full">
                <i class="ri-building-2-fill group-hover:text-black text-8xl text-[#ffffffa8]"></i>
                <h1 className="text-xl group-hover:text-black text-[#ffffffa8]">
                  Dijital Pazarlama
                </h1>
              </div>

              <div className="flex flex-col gap-2">
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="sosyalMedyaReklami"
                    classname={"sr-only"}
                    value={sosyalMedyaReklami}
                    onClick={() =>
                      setWorkData({
                        ...workData,
                        sosyalMedyaReklami: !sosyalMedyaReklami,
                      })
                    }
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          sosyalMedyaReklami ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Sosyal Medya Reklamları
                  </span>
                </label>
                <label className="relative inline-flex items-center cursor-pointer">
                  <Input
                    type="checkbox"
                    name="sosyalMedyaYönetimi"
                    classname={"sr-only"}
                    value={sosyalMedyaYönetimi}
                    onClick={() =>
                      setWorkData({
                        ...workData,
                        sosyalMedyaYönetimi: !sosyalMedyaYönetimi,
                      })
                    }
                  />

                  <div className="min-w-6">
                    <div className="relative w-6 h-6 rounded-md border group-hover:border-black/50 bg-white bg-opacity-25">
                      <div
                        className={`absolute top-[2px] left-[2px] w-[18px] h-[18px] rounded-md bg-white group-hover:bg-blue-800 bg-opacity-70 ${
                          sosyalMedyaYönetimi ? "block" : "hidden"
                        }`}
                      />
                    </div>
                  </div>
                  <span className="group-hover:text-black transition-all duration-300 ps-2 text-xl">
                    Sosyal Medya Yönetimi
                  </span>
                </label>
              </div>
            </div>
          </div>
          <Button
            text={"İleri"}
            style={2}
            color={"white"}
            onClick={() => nextButtonHandler()}
          />
        </div>
      </div>
    );
  };

  const sendTradeHandle = (e) => {
    e.preventDefault();
    if (
      email === "" ||
      firstname === "" ||
      lastname === "" ||
      phoneNumber === ""
    ) {
      toast.warning("Lütfen gerekli alanları eksiksiz doldurunuz.");
    } else if (!kvkk) {
      toast.warning("KVKK şartlarını kabul etmelisiniz.");
    } else {
      sendTrade({
        firstname,
        lastname,
        email,
        phoneNumber,
        companyName,
        website,
        webYazilim,
        mobil,
        logo,
        kimlik,
        video,
        sosyalMedyaReklami,
        sosyalMedyaYönetimi,
      });
    }
  };

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    if (isSuccess) {
      console.log(isSuccess);
    }
  }, [isSuccess, error]);

  return (
    <div className={`pt-48 relative w-full min-h-screen select-none pb-20 overflow-hidden`}>
      <div className="absolute top-0 left-0 w-full h-screen  -z-10">
        <img src="/images/bg-shadow4.webp" alt="" />
      </div>

      <motion.div
        key={step}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={stepVariants}
        transition={{ duration: 0.5 }}
      >
        {step === 1 && <StepOne />}
        {step === 2 && <StepTwo />}
        {step === 3 && (
          <div className="flex justify-center items-center w-full h-full">
            <div className="relative lg:w-1/2 lg:h-96 max-lg:w-full max-lg:h-full max-sm:mx-5 max-lg:mx-10 bg-white/5 border border-white/20 rounded-3xl flex justify-between items-center flex-col px-5 py-10 gap-10">
              <div
                className="absolute top-0 left-0 flex justify-center w-full"
                onClick={() => decreaseStep(1)}
              >
                <div className="max-sm:w-10 max-sm:h-10 max-sm:-mt-5 w-16 h-16 rounded-full border bg-white bg-opacity-10 -mt-8 flex justify-center items-center cursor-pointer">
                  <i class="ri-arrow-left-line max-sm:text-xl text-2xl text-white"></i>
                </div>
              </div>

              <form className="flex flex-col justify-between items-center w-full h-full">
                <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-5 w-full sm:px-10 py-5">
                  {inputItems?.map((item, index) => (
                    <Input
                      type={item.type}
                      name={item.name}
                      required={true}
                      classname={
                        "bg-transparent text-white rounded-full py-2 border border-[#ffffffa8] ps-2 w-full"
                      }
                      placeholder={item.placeholder}
                      onChange={item.onChange}
                      value={item.value}
                    />
                  ))}
                </div>

                {/* kvkk button */}
                <div className="flex max-sm:flex-col gap-5">
                  <div className=" relative inline-flex items-center cursor-pointer">
                    <Input type={"checkbox"} classname={"sr-only"} />
                    <div className="min-w-6">
                      <div
                        className="relative w-5 h-5 rounded-full border bg-white bg-opacity-25 "
                        onClick={() => setKvkk(!kvkk)}
                      >
                        <div
                          className={`absolute top-[3px] left-[3px] w-3 h-3 rounded-full bg-black bg-opacity-70 ${
                            kvkk ? "block" : "hidden"
                          }`}
                        ></div>
                      </div>
                    </div>

                    <p className="ps-2 text-white">
                      <span
                        className="underline underline-offset-4"
                        onClick={() => increaseStep(4)}
                      >
                        KVKK
                      </span>{" "}
                      şartlarını okudum ve kabul ediyorum
                    </p>
                  </div>

                  <div className="">
                    <Button
                      text={"Gönder"}
                      onClick={sendTradeHandle}
                      style={1}
                      color={"purple"}
                      className={"py- w-full"}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {step === 4 && (
          <div className="flex justify-center items-center px-10">
            <div className="relative max-w-[800px] max-h-[600px] bg-black bg-opacity-35 rounded-xl border border-[#ffffffa8] overflow-x-auto text-white kvkk-modal">
              <div className="sticky z-10 top-0 left-0 w-full flex justify-end items-center bg-gradient-to-b from-black to-transparent ">
                <div
                  className="relative group z-20 w-16 h-16 rounded-lg flex justify-center items-center bg-transparent border border-[#ffffffa8] hover:bg-white transition-all duration-300 -mt-2 -me-2 pt-2 pe-2 cursor-pointer"
                  onClick={() => increaseStep(3)}
                >
                  <i class="ri-arrow-left-line text-2xl group-hover:text-black transition-all duration-300"></i>
                </div>
              </div>

              <div className="sm:p-10 -mt-20 -mb-48">
                {t("kvkkPage.sections", { returnObjects: true }).map(
                  (item, index) => (
                    <div className="mt-10 max-sm:px-8">
                      <h3 className={`font-bold text-xl`}>{item?.title}</h3>
                      <p
                        className="font-light mt-3 break-words"
                        dangerouslySetInnerHTML={{ __html: item?.content }}
                      />
                    </div>
                  )
                )}
              </div>
              <div className="sticky bottom-0 left-0 w-full h-48 bg-gradient-to-t from-purple-800 to-transparent" />
            </div>
          </div>
        )}
        {step === 5 && <StepFive />}
      </motion.div>
    </div>
  );
};

export default OfferPage;
